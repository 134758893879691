@import 'breakpoint-slicer';
@import '@assets/scss/breakpoints.scss';
@import '@assets/scss/extend.scss';

.cookie-wrapper {
  position: fixed;
  bottom: 80px;
  right: 20px;
  z-index: 5;

  @include from('medium') {
    bottom: 20px;
    right: 20px;
  }
}

.cookie-button {
  background-color: var(--color-secondary);
  border: none;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  cursor: pointer;
  transition: transform 250ms ease-out;

  &:hover {
    transform: scale(1.1);
  }
}
