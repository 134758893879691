@import 'breakpoint-slicer';
@import '@assets/scss/breakpoints.scss';
@import '@assets/scss/extend.scss';

.campsite-data {
  border-radius: 5px;
  width: 100%;
  border-collapse: collapse;
  border-radius: 5px;
  overflow: hidden;
  box-sizing: border-box;
  background-color: var(--color-surface-quaternary);
  border: 1px solid var(--color-surface);

  th,
  td {
    border: 1px solid var(--color-surface);
    border-collapse: collapse;
  }

  thead {
    background-color: var(--color-surface-secondary);
    position: sticky;
    top: 0;

    th {
      font-size: var(--fs-small);
      color: var(--color-default);
      font-weight: 600;
      font-family: var(--ff-secondary);
      padding: 5px 10px;
    }
  }

  tbody {
    overflow: auto;
    box-sizing: border-box;
    tr {
      .important {
        p {
          background-color: var(--color-surface-secondary);
          color: var(--color-default);
          border-radius: 10px;
          padding: 2px 10px;
          display: inline-block;
          word-break: normal;
        }
      }

      &:nth-child(2n) {
        background-color: var(--color-surface);
      }
    }
    td {
      word-break: break-all;
      padding: 10px 5px;
      text-align: center;
      font-size: var(--fs-small);
      p {
        margin: 0;
        padding: 0;
      }
    }
  }
}
