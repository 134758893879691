.alert-enter {
  opacity: 0;
  transform: scale(0.9);
}
.alert-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 300ms, transform 300ms;
}
.alert-exit {
  opacity: 1;
}
.alert-exit-active {
  opacity: 0;
  transform: scale(0.9);
  transition: opacity 300ms, transform 300ms;
}

.slideLeft-enter {
  opacity: 0;
  transform: translateX(-100%);
}

.slideLeft-enter-active {
  transform: translateX(0);
  opacity: 1;
  transition: transform 300ms, opacity 150ms;
}

.slideLeft-exit {
  transform: translateX(0);
  opacity: 1;
}

.slideLeft-exit-active {
  transform: translateX(-100%);
  transition: transform 300ms, opacity 150ms;
  opacity: 0;
}

.fade-enter {
  opacity: 0;
}
.fade-enter-active {
  opacity: 1;
  transition: opacity 400ms;
}
.fade-exit {
  opacity: 1;
}
.fade-exit-active {
  opacity: 0;
  transition: opacity 400ms;
}

.slideBottom-enter {
  transform: translateY(100%);
}
.slideBottom-enter-active {
  transform: translateY(0);
  transition: transform 300ms ease-out;
}
.slideBottom-exit {
  transform: translateY(0);
}
.slideBottom-exit-active {
  transform: translateY(100%);
  transition: transform 300ms ease-out;
}

.slideRight-enter {
  transform: translateX(100%);
}

.slideRight-enter-active {
  transform: translateX(0);
  transition: transform 300ms ease-out;
}

.slideRight-exit {
  transform: translateX(0);
}

.slideRight-exit-active {
  transform: translateX(100%);
  transition: transform 300ms ease-out;
}
