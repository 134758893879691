@import 'breakpoint-slicer';
@import '@assets/scss/breakpoints.scss';
@import '@assets/scss/extend.scss';

.issue-reporting {
  padding: 20px 10px;
  box-sizing: border-box;
  border-radius: 0 0 20px 20px;
  background-color: var(--color-surface);
  display: grid;
  gap: 20px;
  grid-template-columns: 1fr;

  @include from('medium') {
    grid-template-columns: 1fr 1fr;
  }

  & + .issue-reporting {
    margin-top: 20px;
    border-radius: 20px;
  }

  .meta {
    display: grid;
    grid-template-columns: 30% 1fr;
    align-items: center;
    figure {
      padding: 0;
      margin: 0;

      img {
        width: 100%;
      }
    }

    p {
      margin: 0;
      padding: 0;
    }

    .issue-reporting-title {
      font-size: var(--fs-caption);
      font-weight: 600;
      margin-right: 10px;
      display: inline-block;
      margin-bottom: 10px;
    }

    &-info {
      font-size: var(--fs-small);
    }
  }

  .issue-reporting-refs {
    box-sizing: border-box;
    @include from('medium') {
      padding: 20px;
    }

    .issue-reporting-category {
      background-color: var(--color-surface-secondary);
      color: var(--color-primary);
      padding: 5px 10px;
      border-radius: 20px;
      font-weight: 600;
      display: inline-block;
      margin: 0;

      small {
        display: inline-block;
        margin-left: 10px;
        font-size: var(--fs-caption);
        color: var(--color-tertiary-dark);
      }
    }

    .issue-reporting-state {
      font-size: var(--fs-caption);
      font-weight: 600;
      display: inline-block;
      padding: 5px 10px;
      border-radius: 20px;
      &.open {
        background-color: var(--color-surface-senary);
        color: var(--color-quaternary);
      }

      &.complete {
        background-color: var(--color-surface-octonary);
        color: var(--color-quinary);
      }
    }

    .issue-reporting-status {
      padding: 0;
      margin: 0;
    }

    .issue-reporting-info {
      font-size: var(--fs-small);
      // color: var(--color-tertiary-dark);
      margin: 10px 0;
      padding: 0;
    }
  }

  .issue-reporting-body {
    background-color: var(--color-surface-quaternary);
    line-height: 25px;
    font-size: var(--fs-small);
    border-radius: 10px;
    padding: 10px;
    box-sizing: border-box;

    @include from('medium') {
      padding: 20px;
    }

    small {
      display: block;
      font-size: var(--fs-tiny);
      letter-spacing: 0.2px;
      color: var(--color-tertiary-dark);
      font-weight: 600;
    }
  }
}
