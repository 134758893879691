@import 'breakpoint-slicer';
@import '@assets/scss/breakpoints.scss';
@import '@assets/scss/extend.scss';

.cookie-banner {
  background-color: var(--color-surface);
  padding: 20px;
  border-radius: 4px;
  position: absolute;
  box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.25);
  top: -20px;
  right: 0;
  width: calc(90vw - 20px);
  transform: translateY(-100%);
  box-sizing: border-box;
  color: var(--color-default);
  @include from('medium') {
    width: 400px;
  }
  .buttons-wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 0 15px;

    svg {
      width: 20px;
      height: 20px;
    }
  }

  p {
    font-size: var(--fs-small);
    line-height: 25px;
  }

  h3 {
    font-size: var(--fs-h3);
    margin: 0;
    display: inline-block;
  }

  h2 {
    font-size: var(--fs-h2);
    display: inline-block;
    margin: 5px 0;
    color: var(--color-primary);
  }

  .image {
    position: absolute;
    top: 10px;
    right: 20px;
    font-size: var(--fs-big-size);
    text-shadow: 0px 2px 3px rgba(0, 0, 0, 0.25);
  }
}
