@import 'breakpoint-slicer';
@import '@assets/scss/breakpoints.scss';
@import '@assets/scss/extend.scss';

.report-details {
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  background-color: var(--color-body-background);
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.25);
  overflow: auto;
  padding: 30px;

  .report-content {
    background-color: var(--color-surface);
    border-radius: 5px;
    padding: 20px;
    margin-top: 30px;

    &-header {
      display: grid;
      gap: 20px;
      grid-template-columns: 40px 1fr;
      place-items: center;

      figure {
        margin: 0;
        padding: 0;
      }

      &-data {
        span {
          font-weight: 600;
          font-size: var(--fs-small);
        }

        p {
          padding: 0;
          margin: 5px 0 0;
          font-size: var(--fs-small);
          color: var(--color-tertiary-dark);
        }
      }
    }

    .report-form {
      label {
        display: block;
        padding: 20px 0;
        span {
          font-size: var(--fs-small);
          color: var(--color-tertiary-dark);
          display: grid;
          grid-template-columns: 1fr 20px;
          position: relative;
          align-items: center;

          svg {
            width: 20px;
            place-self: center;
          }

          &:after {
            content: '';
            background-color: black;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
          }
        }

        select {
          border: none;
          padding: 0;
          width: 100%;
          background-color: var(--color-surface);
          font-size: var(--fs-body);
          font-weight: 500;
          font-family: inherit;
          margin-top: 5px;
          margin-left: -4px;
        }

        & + label {
          border-top: 1px dashed var(--color-tertiary-dark);
        }

        textarea {
          width: 100%;
          border: none;
          padding: 0;
          font-family: inherit;
          font-size: inherit;
          font-weight: inherit;
          margin-top: 5px;

          &::placeholder {
            font-size: var(--fs-body);
            font-weight: inherit;
            color: var(--color-default);
            font-family: inherit;
          }
        }
      }
    }
  }
}
