@import 'breakpoint-slicer';
@import '@assets/scss/breakpoints.scss';
@import '@assets/scss/extend.scss';

.dangerous-button {
  @extend .button-3;
  outline: 0;
  position: relative;
  padding: 10px 20px;
  display: grid;
  grid-template-columns: 30px auto;
  gap: 10px;
  place-items: center;

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  .icon-wrapper {
    border-radius: 50%;
    background-color: var(--color-surface);
    width: 30px;
    display: grid;
    place-items: center;
    height: 30px;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  }

  > div {
    text-align: left;
    font-size: var(--fs-caption);
    color: var(--color-default-secondary);
    font-family: var(--ff-primary);
    span {
      display: block;
      color: var(--color-quaternary);
      font-size: var(--fs-small);
      font-family: var(--ff-secondary);
    }
  }
}
