@import 'breakpoint-slicer';
@import '@assets/scss/breakpoints.scss';
@import '@assets/scss/extend.scss';

.logout-button {
  background-color: var(--color-surface-senary);
  border: none;
  width: 100%;
  padding: 15px;
  border-radius: 10px;
  text-align: center;
  margin-top: 100px;
  span {
    color: var(--color-quaternary);
    font-weight: 600;
    display: inline-block;
    margin-left: 10px;
    font-size: var(--fs-body);
  }

  svg {
    height: 16px;
    vertical-align: middle;
  }
}
