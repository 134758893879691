@import 'breakpoint-slicer';
@import '@assets/scss/breakpoints.scss';
@import '@assets/scss/extend.scss';

.carousel-history {
  padding: 20px;
  background-color: var(--color-surface);

  &-header {
    display: grid;
    grid-template-columns: 40px 1fr;
    gap: 20px;

    figure {
      padding: 0;
      margin: 0;
    }

    &-data {
      display: grid;
      grid-template-columns: 1fr auto;

      .carousel-history-header-category {
        span {
          font-weight: 600;
          font-size: var(--fs-small);
        }

        p {
          margin: 5px 0;
          padding: 0;
          font-size: var(--fs-small);
          color: var(--color-tertiary-dark);
        }
      }

      .caousel-history-status {
        text-transform: capitalize;
      }

      .carousel-history-season {
        font-size: var(--fs-small);
        color: var(--color-default-secondary);
        padding: 3px 8px;
        font-family: var(--ff-secondary);
        background-color: var(--color-secondary);
        place-self: start;
        border-radius: 2px;
        font-weight: 600;
      }
    }
  }

  .carousel-history-content {
    margin-top: 10px;

    &-by {
      display: grid;
      grid-template-columns: 1fr 1fr;
      padding: 20px 0;
      gap: 20px;
      border-bottom: 1px dashed var(--color-tertiary-dark);
    }

    &-label {
      padding: 20px 0;
    }

    .carousel-history-item {
      &-category {
        font-size: var(--fs-small);
        color: var(--color-tertiary-dark);
        display: block;

        + p,
        + time {
          margin: 5px 0 0;
          font-size: var(--fs-small);
          // font-weight: 600;
          padding: 0;
        }
      }
    }
  }
}
.dots-wrapper {
  margin: 10px 0 0;
  display: grid;
  place-items: center;
  .dots {
    display: flex;
    .dot {
      width: 6px;
      height: 6px;
      border-radius: 50%;
      background-color: #8f8f8f;

      + .dot {
        margin-left: 5px;
      }

      &.active {
        background-color: var(--color-secondary);
      }
    }
  }
}
