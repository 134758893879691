.new-pending-units {
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  background-color: var(--color-body-background);
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.25);
  overflow: auto;
  padding: 30px;
}
