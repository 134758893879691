@import 'breakpoint-slicer';
@import '@assets/scss/breakpoints.scss';
@import '@assets/scss/extend.scss';

.form-section {
  &-base-navigation {
    background-color: var(--color-surface);
    padding: 10px;
    box-sizing: border-box;
    border-top: 1px solid var(--color-tertiary-light);
    border-radius: 0 0 20px 20px;

    @include from('medium') {
      padding: 40px 30px 10px;
      position: sticky;
      z-index: 2;
      top: 84px;
    }
  }
}

.form {
  margin: 20px 0 0;
  display: grid;
  gap: 20px;
  grid-template-columns: 1fr;

  @include from('medium') {
    grid-template-columns: 1fr auto;
  }

  form {
    order: 2;
    @include from('medium') {
      order: 1;
    }
  }

  .form-actions {
    order: 1;
    position: sticky;
    top: 10px;

    @include from('medium') {
      order: 2;
      position: relative;
      top: auto;
    }

    &-wrapper {
      display: flex;
      flex-direction: column;
      gap: 20px;
      border-radius: 10px;
      background-color: var(--color-surface);
      padding: 20px 10px;
      box-sizing: border-box;
      position: sticky;
      top: 195px;

      @include from('medium') {
        position: sticky;
        top: 195px;
        flex-direction: column;
        gap: 20px;
      }

      .form-buttons-wrapper {
        display: flex;
        gap: 10px 20px;
        flex-wrap: wrap;
        @include from('medium') {
          display: grid;
          gap: 20px;
          grid-template-columns: 1fr;
        }
      }

      .tags {
        position: relative;
        background-color: var(--color-surface-quaternary);
        border-radius: 10px;
        display: flex;
        gap: 5px;
        flex-wrap: wrap;
        padding: 10px 0;
        justify-content: center;
        box-sizing: border-box;

        @include from('medium') {
          display: grid;
          place-items: center;
          gap: 5px;
        }

        > div {
          box-sizing: border-box;
          margin: 0;
        }
      }
    }
  }

  .content-container {
    grid-gap: 20px;
    display: block;
    @include from('medium') {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 20px;
    }

    .form-input-block {
      background-color: var(--color-surface);
      border-radius: 30px 30px 20px 20px;

      > div {
        position: relative;
        border-top: 1px solid var(--color-tertiary-light);
        background-color: var(--color-surface);
        padding: 20px;
        border-radius: 0 0 20px 20px;

        label {
          display: block;
          // grid-template-columns: 40px 1fr;
          margin: 0;

          > span {
            display: inline-block;
            width: auto;

            & + span {
              color: var(--color-default);
            }
          }
        }
      }

      .article-content {
        .previous-value {
          display: grid;
          grid-template-columns: 1fr 40px 1fr;
          gap: 10px;

          &.changed-value {
            p {
              color: var(--color-default-tertiary);
              background-color: var(--color-surface-senary);
            }
          }
          &-input {
            margin-top: 15px;

            small {
              font-size: var(--fs-tiny);
              font-family: var(--ff-primary);
              text-transform: uppercase;
              font-weight: 600;
              letter-spacing: 0.2px;
              display: block;
              color: var(--color-tertiary-dark);
            }

            p {
              border-radius: 10px;
              padding: 2px 10px;
              display: inline-block;
              color: var(--color-default);
              font-size: var(--fs-small);
              margin: 5px 0 0;
            }
          }

          .arrow {
            transform: scale(-1, 1) rotateZ(-90deg);
            position: relative;
            width: 30px;
            height: 30px;
            background-color: var(--color-surface-secondary);
            border-radius: 5px;
            margin-top: 15px;
            place-self: center;

            @include from('medium') {
              place-self: unset;
              align-self: center;
              transform: scale(-1, 1);
            }

            svg {
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
              width: 14px;
              path {
                fill: var(--color-primary);
              }
            }
          }

          > div {
            text-align: center;

            @include from('medium') {
              text-align: left;
            }
          }
        }
      }
    }
  }
}
