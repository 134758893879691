@import 'breakpoint-slicer';
@import '@assets/scss/breakpoints.scss';
@import '@assets/scss/extend.scss';

.homepage {
  header {
    position: relative;
    .content {
      display: grid;
      grid-template-columns: 1fr 40px;
      gap: 30px;
      box-sizing: border-box;

      .homepage-form {
        label {
          position: relative;
          input[type='text'] {
            width: 100%;
            height: 45px;
            background: #ffffff;
            box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.05);
            border-radius: 10px;
            border: none;
            padding: 15px 15px 15px 45px;
            box-sizing: border-box;
            color: var(--color-default);
            font-size: var(--fs-small);

            &::placeholder {
              color: var(--color-tertiary-dark);
            }
          }

          svg {
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
            height: 17px;
          }
        }
      }
    }

    .profile-picture {
      margin: 0;
      padding: 0;
      width: 40px;
      height: 40px;
      overflow: hidden;
      align-self: center;

      img {
        width: 40px;
        height: 40px;
        object-fit: cover;
        background-color: var(--color-default);
        border-radius: 50%;
      }
    }

    .suggestions-list {
      background-color: var(--color-surface);
      width: 100%;
      box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.05);
      border-radius: 10px;
      margin: 10px 0 0 0;
      padding: 0;
      list-style: none;
      font-size: var(--fs-small);
      max-height: 250px;
      overflow: auto;
      position: absolute;
      z-index: 1;

      li {
        padding: 10px 15px;
        text-align: center;
        box-sizing: border-box;
        color: var(--color-default);

        + li {
          border-top: 1px solid var(--color-tertiary);
        }

        &:active {
          background-color: var(--color-tertiary);
        }
      }
    }
  }
}
.section-block {
  .section-grid {
    display: grid;
    grid-template-columns: 40% 1fr;
    box-sizing: border-box;
    gap: 20px;

    .status {
      background: #ffffff;
      box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
      border-radius: 10px;

      ul {
        padding: 10px;
        margin: 0;
        list-style: none;

        li {
          display: flex;
          justify-content: space-between;
          font-size: var(--fs-small);

          + li {
            margin-top: 5px;
          }

          &.status-state {
            font-size: var(--fs-h1);
            font-weight: 600;
            text-align: center;
            display: grid;
            place-content: center;
            margin-top: 15px;
          }
        }
      }
    }
  }

  .section-title {
    font-size: var(--fs-h2);
    display: block;
    font-family: var(--ff-secondary);
    margin-bottom: 15px;
  }

  + .section-block {
    margin-top: 30px;
  }
}
