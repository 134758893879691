@import 'breakpoint-slicer';
@import '@assets/scss/breakpoints.scss';
@import '@assets/scss/extend.scss';

.transfer-form-wrapper {
  box-sizing: border-box;
  max-width: var(--max-body-width);
  margin: 0 auto;

  @include from('medium') {
    padding: 30px 20px;
  }

  .upper-header {
    margin-bottom: 20px;
    padding: 5px;
    z-index: 3;

    @include from('medium') {
      position: sticky;
      top: 84px;
    }
  }
}
