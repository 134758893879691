@import 'breakpoint-slicer';
@import '@assets/scss/breakpoints.scss';
@import '@assets/scss/extend.scss';

.percent-cell-renderer {
  position: absolute;
  height: 100%;
  width: 100%;
  display: grid;
  align-items: center;
  padding: 0 17px;
  top: 0;
  left: 0;
  span {
    font-weight: 600;
  }

  .progress-bar {
    width: 100px;
    height: 10px;
    position: relative;
    border: 1px solid var(--color-tertiary);
    border-radius: 2px;
    place-items: center;
    overflow: hidden;
    .progress-fill {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
    }

    .danger {
      background-color: var(--color-alert-level-4);
    }

    .warning {
      background-color: var(--color-alert-level-3);
    }

    .moderate {
      background-color: var(--color-alert-level-2);
    }

    .success {
      background-color: var(--color-alert-level-1);
    }
  }
}
