@import 'breakpoint-slicer';
@import '@assets/scss/breakpoints.scss';
@import '@assets/scss/extend.scss';

.news-view {
  position: fixed;
  height: 100%;
  width: 100%;
  background-color: var(--color-body-background);
  top: 0;
  left: 0;
  padding: 30px;
  box-sizing: border-box;
  z-index: 2;

  .news-content {
    background-color: var(--color-surface-octonary);
    border-radius: 10px;
    color: var(--color-quinary);
    padding: 15px;
    line-height: 26px;
    margin-top: 30px;

    &-title {
      line-height: 26px;
      font-weight: 600;
    }
  }
}
