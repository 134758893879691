@import 'breakpoint-slicer';
@import '@assets/scss/breakpoints.scss';
@import '@assets/scss/extend.scss';

.export-button {
  @extend .button-2;
  outline: 0;
  position: relative;
  padding: 10px 20px;
  display: grid;
  grid-template-columns: 30px auto;
  gap: 10px;
  place-items: center;
  font-family: var(--ff-secondary);

  &.invert {
    background-color: var(--color-tertiary-light);
    span {
      color: var(--color-default);
    }

    svg {
      path {
        fill: var(--color-default);
      }
    }
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  .icon-wrapper {
    border-radius: 50%;
    background-color: var(--color-surface);
    width: 30px;
    display: grid;
    place-items: center;
    height: 30px;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  }

  > div {
    text-align: left;
    font-size: var(--fs-caption);
    color: var(--color-tertiary-dark);
    font-family: var(--ff-primary);
    span {
      display: block;
      color: var(--color-primary);
      font-size: var(--fs-small);
      font-family: var(--ff-secondary);
    }
  }

  .notification-icon {
    svg {
      animation: ring 4s 0.7s ease-in-out infinite;
      transform-origin: 50% 4px;
    }
  }
}

@keyframes ring {
  0% {
    -webkit-transform: rotateZ(0);
  }
  1% {
    -webkit-transform: rotateZ(30deg);
  }
  3% {
    -webkit-transform: rotateZ(-28deg);
  }
  5% {
    -webkit-transform: rotateZ(34deg);
  }
  7% {
    -webkit-transform: rotateZ(-32deg);
  }
  9% {
    -webkit-transform: rotateZ(30deg);
  }
  11% {
    -webkit-transform: rotateZ(-28deg);
  }
  13% {
    -webkit-transform: rotateZ(26deg);
  }
  15% {
    -webkit-transform: rotateZ(-24deg);
  }
  17% {
    -webkit-transform: rotateZ(22deg);
  }
  19% {
    -webkit-transform: rotateZ(-20deg);
  }
  21% {
    -webkit-transform: rotateZ(18deg);
  }
  23% {
    -webkit-transform: rotateZ(-16deg);
  }
  25% {
    -webkit-transform: rotateZ(14deg);
  }
  27% {
    -webkit-transform: rotateZ(-12deg);
  }
  29% {
    -webkit-transform: rotateZ(10deg);
  }
  31% {
    -webkit-transform: rotateZ(-8deg);
  }
  33% {
    -webkit-transform: rotateZ(6deg);
  }
  35% {
    -webkit-transform: rotateZ(-4deg);
  }
  37% {
    -webkit-transform: rotateZ(2deg);
  }
  39% {
    -webkit-transform: rotateZ(-1deg);
  }
  41% {
    -webkit-transform: rotateZ(1deg);
  }

  43% {
    -webkit-transform: rotateZ(0);
  }
  100% {
    -webkit-transform: rotateZ(0);
  }
}
