@import 'breakpoint-slicer';
@import '@assets/scss/breakpoints.scss';
@import '@assets/scss/extend.scss';

.popup {
  background-color: var(--color-surface);
  border-radius: 5px;
  position: fixed;
  width: calc(100% - 60px);
  bottom: 75px;
  left: 50%;
  transform: translateX(-50%);
  padding: 15px;

  span {
    color: var(--color-tertiary-dark);
    font-size: var(--fs-small);
    display: inline-block;
    margin-bottom: 10px;
  }

  p {
    margin: 0;
    padding: 0;
    font-weight: 600;
    font-size: var(--fs-small);
    line-height: 22px;
  }
}
