@import 'breakpoint-slicer';
@import '@assets/scss/breakpoints.scss';
@import '@assets/scss/extend.scss';

.pending-view {
  position: fixed;
  height: 100%;
  width: 100%;
  background-color: var(--color-body-background);
  top: 0;
  left: 0;
  padding: 30px;
  box-sizing: border-box;
  z-index: 2;
  overflow: auto;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.25);

  .pending-wrapper {
    margin-top: 30px;

    .pending-item {
      background-color: var(--color-surface);
      padding: 20px;
      border-radius: 5px;
      display: grid;
      grid-template-columns: 40px 1fr;
      gap: 20px;

      + .pending-item {
        margin-top: 20px;
      }

      figure {
        padding: 0;
        margin: 0;
      }

      .pending-object {
        .pending-content {
          span {
            font-size: var(--fs-small);
            font-weight: 500;
            color: var(--color-tertiary-dark);
          }

          p {
            margin: 0;
            padding: 0;
            font-size: var(--fs-small);
          }

          + .pending-content {
            margin-top: 10px;
          }
        }
      }
    }
  }
}
