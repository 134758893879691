.form-button {
  display: flex;
  justify-content: flex-start;
  gap: 10px;
  align-items: center;
  appearance: none;
  cursor: pointer;
  background-color: transparent;
  border: none;
  position: relative;
  padding: 0;

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  &:hover {
    .background-button {
      width: 100%;
    }
    span {
      color: var(--color-default);
    }

    path {
      fill: var(--color-default);
    }
  }

  .background-button {
    transition: width 150ms ease-in-out;
    position: absolute;
    left: 0;
    top: 0;
    height: 30px;
    width: 30px;
    border-radius: 5px;
  }

  .icon-wrapper {
    display: grid;
    place-items: center;
    height: 30px;
    width: 30px;
    position: relative;
    z-index: 1;
  }

  path {
    transition: fill 150ms linear;
  }

  span {
    color: var(--color-tertiary-dark);
    font-family: var(--ff-primary);
    font-size: var(--fs-small);
    display: inline-block;
    padding: 0 10px 0 0;
    position: relative;
    text-align: left;
    transition: color 150ms linear;
    z-index: 1;
    white-space: nowrap;
  }
}
