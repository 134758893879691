@import 'breakpoint-slicer';
@import '@assets/scss/breakpoints.scss';
@import '@assets/scss/extend.scss';

.setting-button {
  background-color: var(--color-surface-secondary);
  border: none;
  border-radius: 5px;
  padding: 5px 10px;
  color: var(--color-default);
  margin: 5px auto 0;
  cursor: pointer;
  transition: box-shadow 150ms ease-out, transform 150ms ease-out;
  position: relative;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  gap: 10px;
  align-items: center;

  small {
    font-size: var(--fs-caption);
  }

  @include from('medium') {
    margin: 0;
  }

  &:hover {
    box-shadow: 0 0 0;
    transform: translateY(0.5px);
  }

  &:disabled {
    opacity: 0.5;
  }

  .icon-wrapper {
    display: grid;
    place-items: center;
    background-color: var(--color-surface-quaternary);
    border-radius: 5px;

    svg {
      width: 16px;
      padding: 2px;
    }
  }
}
