@import 'breakpoint-slicer';
@import '@assets/scss/breakpoints.scss';
@import '@assets/scss/extend.scss';

.campsite-contacts {
  margin: 20px 0 0;

  ul {
    padding: 0;
    margin: 0;
    list-style: none;
    display: grid;
    grid-template-columns: 1fr;
    gap: 10px;

    @include from('medium') {
      grid-template-columns: repeat(auto-fit, minmax(240px, 0.5fr));
    }

    li {
      padding: 0;
      margin: 0;
      background-color: var(--color-surface-secondary);
      border-radius: 5px;
      padding: 10px;

      figure {
        margin: 0 0 10px;
        padding: 0;

        img {
          height: 17px;
        }
      }

      div {
        font-size: var(--fs-small);
        p {
          margin: 0;
          padding: 0;

          &:first-child {
            font-weight: 600;
            padding: 5px 10px;
            border-radius: 20px;
            background-color: var(--color-surface-quaternary);
            display: inline-block;
            margin-bottom: 10px;
          }

          a {
            color: inherit;
            position: relative;
            display: inline-block;
            text-decoration: none;
            padding: 2px 4px;
            border-radius: 5px;
            overflow: hidden;
            text-transform: lowercase;

            span {
              position: relative;
              z-index: 1;
            }

            &:before {
              content: '';
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
              background-color: var(--color-surface);
              transform: scaleX(0);
              transform-origin: center right;
              transition: transform 250ms ease-in-out;
            }

            &:hover:before {
              transform: scaleX(1);
              transform-origin: center left;
            }
          }
        }
      }
    }
  }
}
