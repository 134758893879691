.gradient-1 {
  background-image: linear-gradient(
    99.09deg,
    var(--color-gradient-primary-1) 0%,
    var(--color-gradient-primary-2) 100%
  );
}

.gradient-2 {
  background-image: linear-gradient(
    99.09deg,
    var(--color-gradient-secondary-1) 0%,
    var(--color-gradient-secondary-2) 100%
  );
}

.gradient-3 {
  background-image: linear-gradient(
    73.1deg,
    var(--color-gradient-tertiary-1) 8%,
    var(--color-gradient-tertiary-2) 86.9%
  );
}

.gradient-4 {
  background-image: linear-gradient(
    63.2deg,
    var(--color-gradient-quaternary-1) 10.9%,
    var(--color-gradient-quaternary-2) 62.6%
  );
}

.gradient-5 {
  background-image: linear-gradient(
    73.1deg,
    var(--color-gradient-quinary-1) 3.04%,
    var(--color-gradient-quinary-2) 116.63%
  );
}

// TITLE SECTION
.h1 {
  font-family: var(--ff-secondary);
  font-size: var(--fs-h1);
  font-weight: 600;
}

.h2 {
  font-family: var(--ff-secondary);
  font-style: normal;
  font-weight: 600;
  font-size: var(--fs-h2);
}

.h3 {
  font-size: var(--fs-body);
  font-weight: 600;
  font-family: var(--ff-secondary);
  color: var(--color-default);
}

.h4 {
  font-size: var(--fs-small);
  font-weight: 600;
  color: var(--color-primary);
}

// BUTTON SECTION
.button-1 {
  font-size: var(--fs-small);
  font-family: var(--ff-primary);
  padding: 10px;
  cursor: pointer;
  color: var(--color-default-secondary);
  border: none;
  appearance: none;
  border-radius: 10px;
  padding: 0 10px;
  height: 55px;
  box-sizing: border-box;
  transition: transform 100ms ease-out;
  background-color: var(--color-primary);

  &:hover:not(:disabled) {
    box-shadow: 0 0 0;
    transform: translateY(2px);
  }
}

.button-2 {
  font-size: var(--fs-small);
  font-family: var(--ff-primary);
  padding: 10px;
  cursor: pointer;
  color: var(--color-primary);
  border: none;
  appearance: none;
  border-radius: 10px;
  padding: 0 10px;
  height: 55px;
  box-sizing: border-box;
  transition: transform 100ms ease-out;
  background-color: var(--color-surface);

  &:hover:not(:disabled) {
    box-shadow: 0 0 0;
    transform: translateY(2px);
  }
}

.button-3 {
  font-size: var(--fs-small);
  font-family: var(--ff-primary);
  padding: 10px;
  cursor: pointer;
  color: var(--color-quaternary);
  border: none;
  appearance: none;
  border-radius: 10px;
  padding: 0 10px;
  height: 55px;
  box-sizing: border-box;
  transition: transform 100ms ease-out;
  background-color: var(--color-surface-senary);

  &:hover:not(:disabled) {
    box-shadow: 0 0 0;
    transform: translateY(2px);
  }
}

// INPUT SECTION
.input-1 {
  span {
    font-size: var(--fs-caption);
    margin-bottom: 5px;
    display: inline-block;
  }

  div {
    border-left: 10px solid var(--color-primary);
    border-radius: 2px;
    margin: 0;
  }

  input {
    font-size: var(--fs-body);
    width: 100%;
    height: 40px;
    border: none;
    border-right: 1px solid var(--color-tertiary);
    border-top: 1px solid var(--color-tertiary);
    border-bottom: 1px solid var(--color-tertiary);
    border-top-right-radius: 2px;
    border-bottom-right-radius: 2px;
    box-sizing: border-box;
    padding: 0 10px;
    color: var(--color-default);
    position: relative;
    outline: 0;

    &::placeholder {
      color: var(--color-tertiary);
      padding: 0 10px;
    }
  }
}
