@import 'breakpoint-slicer';
@import '@assets/scss/breakpoints.scss';
@import '@assets/scss/extend.scss';

.campsite-details {
  box-sizing: border-box;
  max-width: var(--max-body-width);
  margin: 0 auto;

  @include from('medium') {
    padding: 30px 20px;
  }

  .upper-header {
    margin-bottom: 20px;
    padding: 5px;
    z-index: 3;

    @include from('medium') {
      position: sticky;
      top: 84px;
    }
  }

  .campsite-section {
    &-base-navigation {
      background-color: var(--color-surface);
      padding: 10px;
      box-sizing: border-box;
      border-top: 1px solid var(--color-tertiary-light);
      border-radius: 0 0 20px 20px;

      @include from('medium') {
        padding: 40px 30px 10px;
        position: sticky;
        z-index: 2;
        top: 84px;
      }
    }

    .campsite-content {
      display: grid;
      grid-template-columns: 1fr;
      gap: 20px;
      margin-top: 20px;

      @include from('medium') {
        grid-template-columns: 1fr 30%;
      }

      .campsite-article {
        & + .campsite-article {
          margin-top: 20px;
        }

        .unavailable {
          font-size: var(--fs-small);
          margin: 20px 0 0;
        }

        > div {
          background-color: var(--color-surface);
          border-radius: 20px;
          > div {
            padding: 20px 10px;

            @include from('medium') {
              padding: 20px 30px;
            }
            h3 {
              font-size: var(--fs-h3);
              font-weight: 600;
              margin: 0 0 20px;
              color: var(--color-primary);
            }

            .campsite-linked-subtitle {
              display: grid;
              grid-template-columns: 1fr 30px;
              align-items: center;
              gap: 20px;
              margin: 0 0 20px;

              h3 {
                margin: 0;
              }

              button {
                transform: scale(-1, 1);
                height: 30px;
                width: 30px;
                display: grid;
                place-items: center;
                outline: 0;
                background-color: var(--color-surface-secondary);
                border: none;
                border-radius: 5px;
                cursor: pointer;
              }
            }
          }
        }

        .campsite-map-container {
          > div {
            height: 315px;
          }
        }

        .capacity-target-row {
          background-color: var(--color-surface-quaternary);
          padding: 10px 5px;
          border-radius: 5px;
          display: grid;
          grid-template-columns: 1fr 1fr 1fr 1fr;
          gap: 5px;
          place-items: center;

          &-header {
            display: block;
            margin-bottom: 10px;
          }

          div {
            text-align: center;
          }

          > div {
            &:last-child {
              span:last-child {
                padding: 2px 10px;
                border-radius: 10px;
                font-weight: 600;
                &.positive {
                  background-color: var(--color-surface-octonary);
                }
                &.negative {
                  background-color: var(--color-surface-senary);
                }
              }
            }
          }
        }
      }
    }
  }
}

.site-content {
  margin-top: 60px;
  box-sizing: border-box;
  background-color: var(--color-surface);
  padding: 30px;
  border-radius: 4px;
  @include from('medium') {
    // padding-left: 35px;
    box-sizing: border-box;
    display: inline-block;
    grid-template-columns: 1fr 400px;
  }

  .site-grid {
    display: grid;
    gap: 50px 0;
    @include from('medium') {
      grid-template-columns: repeat(auto-fill, minmax(400px, 50%));
    }
    h3 {
      @extend .h3;
    }

    ul {
      padding: 0;
      margin: 0;
      list-style: none;

      p {
        margin: 0;
      }
    }

    .site-in-touch {
      li {
        & + li {
          margin-top: 10px;
        }

        a {
          color: var(--color-primary);
          text-decoration: none;

          &:hover {
            text-decoration: underline;
          }
        }

        svg {
          vertical-align: middle;
          margin-right: 10px;
        }
      }
    }

    .separator {
      margin-left: 50px;
      font-weight: 600;
      margin: 20px 30px;
      box-sizing: border-box;
    }

    .site-data {
      > div {
        + div {
          margin-top: 20px;
        }

        ul {
          li {
            display: grid;
            grid-template-columns: 100px 1fr;

            & + li {
              margin-top: 10px;
            }
          }
        }
      }
    }
  }

  a {
    color: inherit;
  }

  .see-fleet {
    margin: 20px 0;
  }
}

.contract-managers-list {
  line-height: 25px;
}

.map-container {
  box-sizing: border-box;
  margin-top: 60px;
}
