@import 'breakpoint-slicer';
@import '@assets/scss/breakpoints.scss';
@import '@assets/scss/extend.scss';

.tag-cell-renderer {
  color: var(--color-default-secondary);
  display: inline-block;
  padding: 5px 10px;
  line-height: normal;
  border-radius: 35px;
  padding: 2px 10px;
  text-transform: uppercase;
  font-weight: 600;
  font-size: var(--fs-caption);
  letter-spacing: 0.2px;

  &.tag-cell-1 {
    background-color: var(--color-surface-secondary);
    color: var(--color-primary);

    &:before {
      content: '🏷';
      margin-right: 5px;
    }
  }

  &.tag-cell-2 {
    background-color: var(--color-surface-quinary);
    color: var(--color-default-secondary);

    &:before {
      content: '🗂';
      margin-right: 5px;
    }
  }

  &.tag-cell-3 {
    background-color: var(--color-surface-octonary);
    color: var(--color-quinary);

    &:before {
      content: '🔑';
      margin-right: 5px;
    }
  }

  &.tag-cell-4 {
    background-color: var(--color-surface-senary);
    color: var(--color-quaternary);

    &:before {
      content: '🤝';
      margin-right: 5px;
    }
  }

  &.tag-cell-5 {
    background-color: var(--color-surface-senary);
    color: var(--color-default-tertiary);

    &:before {
      content: '🚫 ';
      margin-right: 5px;
    }
  }
}
