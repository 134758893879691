@import 'breakpoint-slicer';
@import '@assets/scss/breakpoints.scss';
@import '@assets/scss/extend.scss';

.in-and-out-ag-grid {
  background-color: var(--color-surface);

  &.fullscreen {
    position: fixed;
    z-index: 10;
    width: 100%;
    height: 100vh;
    overflow: auto;
    padding: 20px;
    box-sizing: border-box;
    top: 0;
    left: 0;

    .grid-wrapper {
      @include from('medium') {
        height: calc(100vh - 115px);
      }
    }
  }

  .grid-wrapper {
    height: 600px;
  }

  .in-and-out-cta {
    display: grid;
    grid-template-columns: 1fr;
    align-items: center;
    margin-bottom: 20px;
    gap: 10px;
    border-radius: 5px;

    @include from('medium') {
      grid-template-columns: 1fr 1fr;
    }
    > *:first-child {
      justify-content: start;
    }

    > *:last-child {
      display: grid;
      justify-content: start;
      @include from('medium') {
        justify-content: end;
      }
    }

    .setting-buttons {
      display: grid;
      grid-template-columns: auto auto;
      gap: 10px;
    }
  }
}
