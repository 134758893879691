@import 'breakpoint-slicer';
@import '@assets/scss/breakpoints.scss';
@import '@assets/scss/extend.scss';

.unit-search {
  position: fixed;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: var(--color-surface);
  left: 0;
  top: 0;
  padding: 20px;
  box-sizing: border-box;
  border-top: 1px solid var(--color-tertiary-light);

  @include from('medium') {
    position: absolute;
    height: auto;
    top: auto;
    bottom: 0;
    border-radius: 0 0 30px 30px;
    transform: translateY(100%);
    overflow: none;
  }

  &-wrapper {
    max-width: var(--max-body-width);
    margin: 0 auto;
    background-color: var(--color-surface-quaternary);
    box-sizing: border-box;
    border-radius: 20px;

    @include from('medium') {
      padding: 0 30px;
    }
  }

  &-base-navigation {
    background-color: var(--color-surface);
    padding: 40px 30px 10px;
    box-sizing: border-box;
    border-top: 1px solid var(--color-tertiary-light);
    border-radius: 0 0 20px 20px;
  }

  &-data {
    max-width: 70%;
    margin-top: 20px;
    padding: 0 30px 20px;
    ul {
      padding: 0;
      margin: 0;
      list-style: none;
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 20px;

      li {
        min-height: 35px;
        p {
          padding: 0;
          margin: 0;
          font-size: var(--fs-small);

          small {
            font-size: var(--fs-tiny);
            font-weight: 600;
            text-transform: uppercase;
            display: block;
            margin-bottom: 5px;
          }
        }
      }
    }

    .buttons-wrapper {
      display: flex;
      justify-content: flex-start;
      gap: 20px;
      margin-top: 20px;
      border-top: 1px solid var(--color-tertiary);
      padding: 20px 0 0;

      .close-button {
        background-color: transparent;
        border: none;
        text-decoration: underline;
        font-family: var(--ff-primary);
        font-size: var(--fs-small);
        padding: 5px 10px;
        cursor: pointer;
      }
    }
  }
}
