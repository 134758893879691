@import 'breakpoint-slicer';
@import '@assets/scss/breakpoints.scss';
@import '@assets/scss/extend.scss';

.popin {
  position: absolute;
  top: 0;
  z-index: 3;
  left: 0;
  width: 100%;
  background-color: var(--color-body-background);
  border-radius: 50px 50px 0 0;
  padding: 30px;
  box-sizing: border-box;
  @include from('medium') {
    padding: 0;
  }

  &-wrapper {
    box-sizing: border-box;
    max-width: var(--max-body-width);
    margin: 0 auto;

    @include from('medium') {
      padding: 30px 0;
    }

    .upper-header {
      margin-bottom: 20px;
      padding: 5px;
      z-index: 3;

      @include from('medium') {
        position: sticky;
        top: 84px;
      }
    }
  }
}
