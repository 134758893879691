@import 'breakpoint-slicer';
@import '@assets/scss/breakpoints.scss';
@import '@assets/scss/extend.scss';

.article-header {
  display: grid;
  grid-template-columns: 60px auto;
  align-items: center;
  background-color: var(--color-surface-quaternary);
  gap: 10px;
  padding: 20px 30px;
  box-sizing: border-box;
  border-radius: 20px 20px 0 0;

  .lottie {
    width: 60px;
  }

  .article-header-content {
    font-size: var(--fs-body);
    font-family: var(--ff-secondary);
    color: var(--color-primary);
    font-weight: 600;

    small {
      color: var(--color-tertiary-dark);
      font-size: var(--fs-caption);
      font-family: var(--ff-primary);
      font-weight: normal;
      display: block;
      margin-top: 5px;
    }
  }
}
