@import 'breakpoint-slicer';
@import '@assets/scss/breakpoints.scss';
@import '@assets/scss/extend.scss';

.unit-details {
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 30px 0;
  box-sizing: border-box;
  background-color: var(--color-body-background);
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.25);
  overflow: auto;

  .unit-header {
    padding: 0 30px;
  }

  .section {
    padding: 30px 30px 50px 30px;

    + .section {
      margin-top: 30px;
    }

    .section-header {
      top: 0;
      figure {
        margin: 0;
        width: 100%;
        border-radius: 10px;
        height: 150px;
        overflow: hidden;
        opacity: 0.6;
        box-shadow: 0 2px 15px rgba(0, 0, 0, 0.25);
        img {
          object-fit: cover;
          width: 100%;
        }
      }

      &-title {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        font-weight: 600;
        font-family: var(--ff-secondary);
        font-size: var(--fs-body);
      }
    }

    .section-content {
      margin-top: 10px;
      background-color: var(--color-surface);
      border-radius: 5px;
    }

    .section-item {
      margin: 0;
      padding: 15px;
      font-size: var(--fs-body);
      padding: 15px;
      box-sizing: border-box;

      + .section-item {
        border-top: 1px dashed var(--color-tertiary);
      }

      span {
        font-size: var(--fs-small);
        display: block;
        margin-bottom: 5px;
        color: var(--color-tertiary-dark);
      }
    }
  }
}
