@import 'breakpoint-slicer';
@import '@assets/scss/breakpoints.scss';
@import '@assets/scss/extend.scss';

.tag-wrapper {
  display: inline-block;
  border-radius: 20px;
  padding: 5px 10px;
  background-color: var(--color-surface-quinary);
  color: var(--color-default-tertiary);

  & + .tag-wrapper {
    margin-left: 10px;
  }

  span {
    display: block;
    line-height: normal;
    border-radius: 35px;
    text-transform: uppercase;
    font-weight: 600;
    font-size: var(--fs-caption);
  }

  &.success {
    background-color: var(--color-surface-octonary);
    color: var(--color-quinary);
    border-radius: 5px;
    span {
      font-size: var(--fs-small);
    }
  }

  &.status {
    background-color: var(--color-surface-octonary);
    color: var(--color-quinary);
    span {
      text-transform: uppercase;
    }
  }

  &.sale {
    background-color: var(--color-surface-senary);
    color: var(--color-quaternary);
    span {
      &:before {
        content: '💳';
        margin-right: 5px;
      }
    }
  }

  &.info {
    background-color: var(--color-surface-secondary);
    color: var(--color-primary);
    span {
      &:before {
        content: '💬';
        margin-right: 5px;
      }
    }
  }

  &.ac_u {
    background-color: var(--color-surface-octonary);
    color: var(--color-quinary);
    span {
      &:before {
        content: '✨';
        margin-right: 5px;
      }
    }
  }

  &.ost {
    background-color: var(--color-surface-quinary);
    color: var(--color-default-tertiary);
    span {
      &:before {
        content: '📍';
        margin-right: 5px;
      }
    }
  }

  &.brsw {
    background-color: var(--color-surface-quinary);
    color: var(--color-default-tertiary);
    span {
      &:before {
        content: '🏷️';
        margin-right: 5px;
      }
    }
  }

  &.new {
    background-color: var(--color-surface-octonary);
    color: var(--color-quinary);
    span {
      &:before {
        content: '🆕';
        margin-right: 5px;
      }
    }
  }

  &.comm {
    background-color: var(--color-surface-secondary);
    color: var(--color-primary);
    span {
      &:before {
        content: '🎟️';
        margin-right: 5px;
      }
    }
  }

  &.unrent {
    background-color: var(--color-surface-quinary);
    color: var(--color-default-tertiary);
    span {
      &:before {
        content: '🔓';
        margin-right: 5px;
      }
    }
  }

  &.deck_r {
    background-color: var(--color-surface-octonary);
    color: var(--color-quinary);
    span {
      &:before {
        content: '🛠️';
        margin-right: 5px;
      }
    }
  }

  &.deck_u {
    background-color: var(--color-surface-octonary);
    color: var(--color-quinary);
    span {
      &:before {
        content: '✨';
        margin-right: 5px;
      }
    }
  }

  &.rfrb {
    background-color: var(--color-surface-octonary);
    color: var(--color-quinary);
    span {
      &:before {
        content: '🛋️';
        margin-right: 5px;
      }
    }
  }

  &.sts {
    background-color: var(--color-surface-quinary);
    color: var(--color-default-tertiary);
    span {
      &:before {
        content: '🚚';
        margin-right: 5px;
      }
    }
  }

  &.sec_hand {
    background-color: var(--color-surface-septenary);
    color: var(--color-secondary);
    span {
      &:before {
        content: '🤝🏻';
        margin-right: 5px;
      }
    }
  }

  &.locked {
    background-color: var(--color-surface-quinary);
    color: var(--color-default-tertiary);
    span {
      &:before {
        content: '🔒';
        margin-right: 5px;
      }
    }
  }
}
