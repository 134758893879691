@import 'breakpoint-slicer';
@import '@assets/scss/breakpoints.scss';
@import '@assets/scss/extend.scss';

.f2m-data-wrapper {
  display: grid;
  grid-template-columns: 20% 1fr;
  gap: 20px;
  @include from('medium') {
    grid-template-columns: 30% 1fr;
  }
}

.widget-header {
  width: 100%;
  box-sizing: border-box;

  figure {
    margin: 0;
    padding: 0;
    img {
      width: 100%;
    }
  }
}

.f2m-data-body {
  background-color: var(--color-surface-quaternary);
  padding: 0 20px;
  box-sizing: border-box;
  border-radius: 10px;

  @include from('medium') {
    padding: 20px;
  }
  .date {
    > div {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 20px;
    }

    p {
      padding: 0;
      font-size: var(--fs-small);
      font-family: var(--ff-secondary);
      font-weight: 600;
      margin: 0;
      text-transform: uppercase;
    }

    small {
      font-size: var(--fs-small);
      color: var(--color-tertiary-dark);
      display: block;

      &.f2m-data-season {
        color: var(--color-default);
        text-transform: uppercase;
        margin-bottom: 5px;
        font-weight: 600;
      }
    }
  }
}

.widget-author {
  margin: 20px 0;

  small {
    margin-bottom: 5px;
    display: inline-block;
    font-size: var(--fs-caption);
    display: block;
  }

  p {
    margin: 0;
    padding: 0;
    display: inline-block;
    background-color: var(--color-surface-secondary);
    color: var(--color-default);
    font-size: var(--fs-small);
    text-transform: uppercase;
    padding: 4px 10px;
    border-radius: 20px;
  }
}

.widget-details {
  small {
    margin-bottom: 5px;
    display: inline-block;
    font-size: var(--fs-caption);
  }

  > ul {
    list-style: none;
    margin: 0;
    padding: 0;

    li {
      padding: 2px 0;
      font-size: var(--fs-small);
    }
  }

  .details-list {
    width: 100%;
    button {
      background-color: var(--color-primary);
      border-radius: 50%;
      height: 30px;
      width: 30px;
      display: grid;
      place-items: center;
      border: 0;
      padding: 0;
      margin: 20px auto 0;
      cursor: pointer;
      transition: all 250ms ease-out, top 100ms;
      top: 0;
      position: relative;

      &:hover {
        top: -3px;
        box-shadow: 0 0 4px var(--color-tertiary-dark);
        transition: all 250ms ease-out;
      }

      &.close {
        transform: rotate(45deg);
      }
    }

    ul {
      list-style: none;
      margin: 0;
      padding: 0;
      background-color: var(--color-surface);
      max-height: 0;
      transition: max-height 300ms ease-out, opacity 100ms linear 200ms;
      overflow: hidden;
      opacity: 0;

      &.active {
        max-height: 100%;
        transition: max-height 300ms ease-out, opacity 100ms linear;
        opacity: 1;
      }

      li {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 10px;
        font-size: var(--fs-small);
        padding: 5px 0;

        span {
          font-weight: 600;
          text-transform: uppercase;
        }

        &:nth-child(even) {
          background-color: var(--color-surface-quaternary);
        }
      }
    }
  }
}

.table-wrapper {
  overflow: auto;
  box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.25);

  .timeline-table {
    background-color: var(--color-surface);
    box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.25);
    border-collapse: collapse;
    box-sizing: border-box;
    width: 100%;
    border-top: 3px solid var(--color-primary);

    tbody {
      &:before,
      &:after {
        content: '';
        display: block;
        height: 20px;
      }

      > tr {
        th {
          color: var(--color-primary);
          padding: 10px 10px;
          font-family: var(--ff-secondary);
        }
      }

      td,
      th {
        vertical-align: middle;
        min-width: 100px;
      }

      .arrow {
        width: 40px;

        div {
          height: 24px;
          width: 24px;
          border-radius: 50%;
          background-color: var(--color-primary);
          display: inline-block;
          position: relative;
        }

        svg {
          width: 20px;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%) scale(-1, 1);
        }
      }
    }
  }
}

.timeline-footer {
  margin-top: 20px;

  svg {
    width: 14px;
    margin-right: 5px;
  }

  button {
    vertical-align: top;
    & + button {
      margin-left: 15px;
    }
  }
}
