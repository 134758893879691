@import 'breakpoint-slicer';
@import '@assets/scss/breakpoints.scss';
@import '@assets/scss/extend.scss';

.logs-wrapper {
  background-color: var(--color-surface-quinary);
  color: var(--color-default);
  max-height: 400px;
  overflow: auto;
  box-sizing: border-box;
  border-radius: 20px;

  .logs {
    padding: 0 10px;

    @include from('medium') {
      padding: 0 30px 30px;
    }

    ul {
      margin: 0;
      padding: 0;
    }
  }

  .reloading {
    margin: 20px 0;
  }
}
