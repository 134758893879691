@import 'breakpoint-slicer';
@import '@assets/scss/breakpoints.scss';
@import '@assets/scss/extend.scss';

dialog {
  border: none;
  border-radius: 0 20px 20px 0;
  background-color: var(--color-body-background);
  position: fixed;
  height: 100%;
  // z-index: 8;
  box-sizing: border-box;
  left: 0;
  top: 0;
  margin: 0;
  width: 100%;
  padding: 10px;

  @include from('medium') {
    height: calc(100% - 84px);
    position: absolute;
    top: auto;
    left: 100%;
    bottom: 0;
    width: 350px;
  }

  .valid-transfers-wrapper {
    .upper-header {
      margin-bottom: 20px;
    }

    select {
      // appearance: ;
      box-sizing: border-box;
      width: 100%;
      font-size: var(--fs-small);
      font-family: var(--ff-main);
      padding: 5px 10px;
      box-shadow: none;
    }

    .confirm-btn {
      margin-top: 15px;
      display: grid;
      justify-content: end;
    }

    p {
      padding: 0;
      margin: 0;
      font-size: var(--fs-small);
      box-sizing: border-box;
      color: var(--color-default);
    }

    .wrapper {
      border-top: 1px solid var(--color-tertiary-light);
      background-color: var(--color-surface);
      padding: 20px 10px;

      @include from('medium') {
        grid-template-columns: repeat(auto-fit, minmax(100px, 33.33%));
        gap: 100px 0;
      }

      .buttons {
        background-color: var(--color-surface-quaternary);
        padding: 20px;
        border-radius: 10px;
        margin-top: 10px;
        display: grid;
        grid-template-columns: 1fr;
        gap: 10px;

        p {
          color: var(--color-tertiary-dark);
        }
      }
    }
  }
}

@keyframes spin {
  0% {
    transform: translate(-50%, -50%) rotate(20deg) scale(1);
  }
  50% {
    transform: translate(-50%, -50%) rotate(20deg) scale(1.2);
  }
  100% {
    transform: translate(-50%, -50%) rotate(380deg) scale(1);
  }
}
