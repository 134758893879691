@import 'breakpoint-slicer';
@import '@assets/scss/breakpoints.scss';
@import '@assets/scss/extend.scss';

.content-container {
  display: flex;
  flex-direction: column;
  padding-bottom: 30px;
  flex-wrap: wrap;

  &-header {
    margin: 10px 0;

    @include from('medium') {
      padding: 0 20px;
      margin: 30px 0 10px;
    }
  }
}

.campsites-header {
  display: grid;
  grid-template-columns: 1fr;
  gap: 10px;

  @include from('medium') {
    display: grid;
    padding: 0 20px;
    grid-template-columns: 50% 50%;
  }

  > div:first-child {
    display: flex;
    gap: 10px;
  }

  > div:last-child {
    display: flex;
    gap: 10px;

    @include from('medium') {
      justify-content: flex-end;
    }
  }
}
