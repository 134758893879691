@import 'breakpoint-slicer';
@import '@assets/scss/breakpoints.scss';
@import '@assets/scss/extend.scss';

.marker {
  position: relative;
  cursor: pointer;
  text-decoration: none;

  &:hover {
    .panel {
      visibility: visible;
    }
  }

  .pin {
    display: block;
    border-radius: 50%;
    height: 20px;
    width: 20px;
    transform: scale(1);
    animation: pulse-black 2s infinite;
    background: rgba(#9d7ef1, 1);
    box-shadow: 0 0 0 0 rgba(#9d7ef1, 1);
    animation: pulse-red 2s infinite;
  }

  .old {
    opacity: 0.5;
  }

  .panel {
    position: absolute;
    visibility: hidden;
    top: -10px;
    left: 50%;
    transform: translate(-50%, -100%);
    background-color: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(100px);
    border-radius: 2px;
    padding: 5px 10px;
    z-index: 2;
    h4 {
      @extend .h4;
      color: var(--color-default-tertiary);
      display: inline-block;
      margin: 0;
      white-space: nowrap;
    }

    .statistics {
      color: var(--color-default-tertiary);
      font-size: var(--fs-body);
      margin-top: 10px;

      .progress-bar {
        position: relative;
        height: 20px;
        width: 100%;
        border: 0.5px solid var(--color-default-tertiary);

        .filled {
          display: inline-block;
          position: absolute;
          left: 0;
          top: 0;
          height: 100%;
          background-color: var(--color-surface-octonary);
        }

        .percent {
          position: absolute;
          width: 100%;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: var(--fs-small);
          color: var(--color-default-tertiary);
        }
      }
    }
  }
}

@keyframes pulse-red {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(#9d7ef1, 0.7);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(#9d7ef1, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(#9d7ef1, 0);
  }
}
