@import 'breakpoint-slicer';
@import '@assets/scss/breakpoints.scss';
@import '@assets/scss/extend.scss';

.unit-type-cell-renderer {
  display: flex;
  align-items: center;
  .unit-type-icon-wrapper {
    line-height: normal;
    padding: 5px 5px;
    background-color: var(--color-surface-secondary);
    color: var(--color-quinary);
    border-radius: 10px;
    display: grid;
    place-items: center;
  }
  span {
    vertical-align: middle;
    display: inline-block;
    margin-left: 5px;
  }
}
