@import 'breakpoint-slicer';
@import '../../assets/scss/breakpoints.scss';
@import '../../assets/scss/extend.scss';

.login-section {
  width: 100%;
  height: 100vh;
  position: relative;

  .login-background {
    @include from(medium) {
      will-change: transform;
      transition: background-image 250ms ease-out;
      width: 70%;
      position: absolute;
      right: 0;
      top: 0;
      height: 100%;
      background-repeat: no-repeat;
      background-position: center center;
      background-size: cover;
    }
  }

  .login-wrapper {
    min-height: 100%;
    display: grid;
    align-content: center;
    background-color: var(--color-surface);
    position: relative;
    z-index: 1;
    padding: 30px;
    box-sizing: border-box;
    @include from(medium) {
      width: 40%;
      border-radius: 0 50px 50px 0;
      padding: 0;
    }
  }

  .login-container {
    width: 100%;
    box-sizing: border-box;

    header {
      font-size: var(--fs-small);
      text-align: center;
      position: relative;
      z-index: 1;

      @include from(medium) {
        color: var(--color-default);
      }

      figure {
        margin: 0;
        padding: 0;
        img {
          width: 137px;
        }
      }

      p {
        margin: 0;
        padding: 0;
        font-size: var(--fs-small);
        color: var(--color-tertiary-dark);
        margin: 50px 0 40px;
        line-height: 25px;
        span {
          font-family: var(--ff-secondary);
          font-weight: 600;
          color: var(--color-default);
          font-size: var(--fs-title);
          display: block;
        }
      }
    }
  }

  .wrapper {
    width: 100%;
    position: relative;
    z-index: 1;
    margin: 50px auto 0;

    @include from(medium) {
      width: 350px;
      padding: 20px;
      margin: 0 auto;
    }
  }

  label {
    // @extend .input-1;
    background-color: var(--color-tertiary-light);
    display: inline-block;
    width: 100%;
    height: 50px;
    display: flex;
    box-sizing: border-box;
    flex-direction: column;
    justify-content: space-between;
    padding: 5px 10px;
    border-radius: 10px;

    > span {
      font-size: var(--fs-tiny);
      text-transform: uppercase;
      color: var(--color-tertiary-dark);
      display: block;
    }

    > input {
      width: 100%;
      box-sizing: border-box;
      border: none;
      background-color: transparent;
      font-size: var(--fs-small);
      outline: none;
      color: var(--color-default);
    }

    & + label {
      margin: 10px 0 0;
      display: block;
    }
  }

  form button[type='submit'] {
    @extend .button-1;
    width: 100%;
  }

  .reset-wrapper {
    margin: 0 0 20px;
  }

  .reset-button {
    color: var(--color-tertiary-dark);
    font-family: inherit;
    padding: 0;
    text-decoration: underline;
    font-size: var(--fs-caption);
    border: none;
    background-color: transparent;
    cursor: pointer;
  }

  .separator {
    margin: 30px 0;
    display: grid;
    grid-template-columns: 1fr auto 1fr;

    .middle-line {
      position: relative;
      height: 100%;

      &:after {
        content: '';
        position: absolute;
        top: 50%;
        left: 0;
        transform: translate(0, -50%);
        height: 1px;
        width: 100%;
        background-color: black;

        @include from(medium) {
          background-color: var(--color-tertiary-light);
        }
      }
    }

    .text {
      color: var(--color-tertiary-dark);
      font-weight: 600;
      font-size: var(--fs-caption);
    }
  }

  small {
    font-size: var(--fs-caption);
    text-align: center;
    margin: 30px 0 0;
    display: block;
    position: relative;
    z-index: 1;
    color: var(--color-tertiary-dark);

    @include from(medium) {
      position: absolute;
      bottom: 20px;
      left: 50%;
      width: 100%;
      transform: translateX(-50%);
    }
  }

  .service-apps {
    button:not(:first-child) {
      margin-top: 15px;
    }
  }

  /* Google button */
  .login-with-google-btn {
    transition: background-color 0.3s, box-shadow 0.3s;
    padding: 10px 10px 10px 42px;
    border: none;
    border-radius: 10px;
    height: 40px;
    box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.04), 0 1px 1px rgba(0, 0, 0, 0.25);
    border: 1px solid var(--color-tertiary);
    color: #757575;
    font-size: var(--fs-small);
    font-weight: 600;
    background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTgiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGcgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj48cGF0aCBkPSJNMTcuNiA5LjJsLS4xLTEuOEg5djMuNGg0LjhDMTMuNiAxMiAxMyAxMyAxMiAxMy42djIuMmgzYTguOCA4LjggMCAwIDAgMi42LTYuNnoiIGZpbGw9IiM0Mjg1RjQiIGZpbGwtcnVsZT0ibm9uemVybyIvPjxwYXRoIGQ9Ik05IDE4YzIuNCAwIDQuNS0uOCA2LTIuMmwtMy0yLjJhNS40IDUuNCAwIDAgMS04LTIuOUgxVjEzYTkgOSAwIDAgMCA4IDV6IiBmaWxsPSIjMzRBODUzIiBmaWxsLXJ1bGU9Im5vbnplcm8iLz48cGF0aCBkPSJNNCAxMC43YTUuNCA1LjQgMCAwIDEgMC0zLjRWNUgxYTkgOSAwIDAgMCAwIDhsMy0yLjN6IiBmaWxsPSIjRkJCQzA1IiBmaWxsLXJ1bGU9Im5vbnplcm8iLz48cGF0aCBkPSJNOSAzLjZjMS4zIDAgMi41LjQgMy40IDEuM0wxNSAyLjNBOSA5IDAgMCAwIDEgNWwzIDIuNGE1LjQgNS40IDAgMCAxIDUtMy43eiIgZmlsbD0iI0VBNDMzNSIgZmlsbC1ydWxlPSJub256ZXJvIi8+PHBhdGggZD0iTTAgMGgxOHYxOEgweiIvPjwvZz48L3N2Zz4=);
    background-color: white;
    background-repeat: no-repeat;
    background-position: 12px 9px;
    margin: 0 auto;
    display: block;
    width: 100%;
    cursor: pointer;
    text-align: left;
  }
  .login-with-google-btn:hover {
    box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.04), 0 2px 4px rgba(0, 0, 0, 0.25);
  }
  .login-with-google-btn:active {
    background-color: #eeeeee;
  }
  .login-with-google-btn:focus {
    outline: none;
    box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.04), 0 2px 4px rgba(0, 0, 0, 0.25),
      0 0 0 3px #c8dafc;
  }
  .login-with-google-btn:disabled {
    filter: grayscale(100%);
    background-color: #ebebeb;
    box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.04), 0 1px 1px rgba(0, 0, 0, 0.25);
    cursor: not-allowed;
  }

  /* Google button */
  .login-with-microsoft-btn {
    transition: background-color 0.3s, color 0.3s;
    padding: 10px 10px 10px 42px;
    border: 1px solid #8c8c8c;
    height: 40px;
    color: #5e5e5e;
    font-size: var(--fs-small);
    font-weight: 600;
    background-image: url(../../assets/images/login/microsoft.png);
    background-color: #fff;
    background-repeat: no-repeat;
    background-position: 12px 9px;
    margin: 0 auto;
    display: block;
    width: 100%;
    cursor: pointer;
    text-align: left;
  }
  .login-with-microsoft-btn:hover,
  .login-with-microsoft-btn:focus,
  .login-with-microsoft-btn:active {
    // box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.04), 0 2px 4px rgba(0, 0, 0, 0.25);
    background-color: #2f2f2f;
    color: #fff;
    border-color: #2f2f2f;
  }
  .login-with-microsoft-btn:disabled {
    filter: grayscale(100%);
    background-color: #ebebeb;
    box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.04), 0 1px 1px rgba(0, 0, 0, 0.25);
    cursor: not-allowed;
  }
}
