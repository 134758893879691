.grid-button {
  background-color: var(--color-primary);
  border: none;
  padding: 4px 10px 4px 4px;
  border-radius: 5px;
  color: var(--color-default-secondary);
  font-family: var(--ff-primary);
  box-sizing: border-box;
  transition: background-color 100ms ease-out;
  cursor: pointer;
  font-weight: 600;
  text-transform: uppercase;
  font-size: var(--fs-caption);
  letter-spacing: 0.2px;
  &:hover {
    background-color: var(--color-surface-secondary);
    color: var(--color-default);
  }

  &.danger {
    background-color: var(--color-quaternary);
  }

  .icon-wrapper {
    display: inline-block;
    background-color: var(--color-surface-secondary);
    padding: 0 2px 2px;
    margin-right: 10px;
    border-radius: 2px;
    box-sizing: border-box;
  }

  svg {
    width: 14px;
    height: 14px;
    vertical-align: middle;
  }

  & + .grid-button {
    margin-left: 10px;
  }
}
