@import 'breakpoint-slicer';
@import '@assets/scss/breakpoints.scss';
@import '@assets/scss/extend.scss';

.load-data-wrapper {
  height: 100vh;
  width: 100%;
  display: grid;
  place-items: center;
  box-sizing: border-box;
  padding: 10px;
  position: relative;

  > figure {
    padding: 0;
    margin: 0;
    position: absolute;
    top: 35px;
    left: 20px;
    img {
      width: 30px;
    }
  }

  .load-data-content {
    max-width: 330px;
  }
}
