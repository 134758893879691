@import 'breakpoint-slicer';
@import '@assets/scss/breakpoints.scss';
@import '@assets/scss/extend.scss';

.snack-bar-content {
  display: inline-block;
  padding: 10px;
  color: var(--color-default);
  position: fixed;
  z-index: 10;
  right: 10px;
  top: 30px;
  background-color: var(--color-surface);
  border-left: none;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0px 0px 10px rgba(112, 112, 112, 0.2);
  max-width: 400px;
  line-height: 22px;

  .content {
    box-sizing: border-box;
    position: relative;

    > div {
      display: grid;
      grid-template-columns: 1fr 40px;
      align-items: center;
    }

    button {
      background: none;
      cursor: pointer;
      border: none;
      appearance: none;
    }
  }

  p {
    place-items: center;
    margin: 5px 0 0;
    color: var(--color-default);
    border-radius: 5px;
    background-color: var(--color-surface-quaternary);
    padding: 10px;
    font-size: var(--fs-small);
    line-height: 25px;
  }

  svg {
    width: 20px;
    vertical-align: middle;
  }

  &.success h1 {
    color: var(--color-quinary);
    text-transform: uppercase;
    background-color: var(--color-surface-octonary);
  }

  &.error h1 {
    color: var(--color-quaternary);
    background-color: var(--color-surface-senary);
  }

  &.info h1 {
    color: var(--color-primary);
    background-color: var(--color-surface-secondary);
  }

  &.warning h1 {
    color: var(--color-secondary);
    background-color: var(--color-surface-septenary);
  }

  h1 {
    font-size: var(--fs-small);
    font-family: var(--ff-secondary);
    margin: 0;
    display: inline-block;
    padding: 5px 10px;
    border-radius: 20px;
  }
}
