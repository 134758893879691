.carousel-component {
  overflow: hidden;
  .carousel {
    margin-top: 40px;

    &-item {
      width: 100%;
      background-color: var(--color-surface-octonary);
      border-radius: 10px;
      color: var(--color-quinary);
      box-sizing: border-box;
      padding: 15px;
      display: grid;
      height: 140px;
      grid-template-columns: 1fr 90px;
      transition: transform 100ms ease-out, opacity 300ms;
    }

    &-content {
      .carousel-tag {
        background-color: var(--color-surface);
        font-size: var(--fs-caption);
        display: inline-block;
        color: var(--color-default);
        padding: 5px;
        border-radius: 5px;
      }

      .carousel-title {
        font-size: var(--fs-body);
        display: block;
        margin-top: 10px;
        font-weight: 600;
        line-height: 26px;
      }
    }

    .carousel-image {
      margin: 0;
      padding: 0;
      place-self: center;

      img {
        mix-blend-mode: soft-light;
      }
    }
  }

  .carousel-pagination {
    margin: 10px 0;
    display: grid;
    place-items: center;
    .dots {
      display: flex;
      .dot {
        width: 6px;
        height: 6px;
        border-radius: 50%;
        background-color: #8f8f8f;

        + .dot {
          margin-left: 5px;
        }

        &.active {
          background-color: var(--color-secondary);
        }
      }
    }
  }
}
