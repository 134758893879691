@import 'breakpoint-slicer';
@import '@assets/scss/breakpoints.scss';
@import '@assets/scss/extend.scss';

.search-wrapper {
  margin-top: 30px;
  position: relative;

  form {
    // display: grid;
    // grid-template-columns: 1fr 40px;

    label {
      position: relative;
      svg {
        position: absolute;
        left: 20px;
        top: 50%;
        transform: translate(0, -50%);
        z-index: 2;
        opacity: 0.7;
        margin-top: 2px;
      }

      input {
        height: 40px;
        padding: 0 40px;
        width: 100%;
        box-sizing: border-box;
        border-radius: 2px 0 0 2px;
        background-color: var(--color-gradient-primary-1);
        border: 1px solid var(--color-tertiary);
        position: sticky;
        box-sizing: border-box;
        text-transform: uppercase;
        z-index: 1;
        background: #365ea3;
        border-radius: 90px;
        outline: 0;
        color: white;
        font-family: var(--ff-primary);

        &::placeholder {
          text-transform: none;
          color: rgba(#ffffff, 0.7);
          font-size: var(--fs-caption);
        }
      }
    }

    // button {
    //   margin: 0;
    //   padding: 0;
    //   border: none;
    //   border-radius: 0 2px 2px 0;
    //   @extend .gradient-1;
    // }

    .suggestions-list {
      max-height: 300px;
      width: 100%;
      box-sizing: border-box;
      overflow: auto;
      background-color: var(--color-surface);
      border: 1px solid var(--color-tertiary);
      margin: 0;
      padding: 0;
      list-style: none;
      position: absolute;
      border-radius: 5px;
      top: 45px;
      left: 0;
      // transform-origin: top top;
      z-index: 1;

      li {
        padding: 10px 10px;
        text-align: center;
        font-size: var(--fs-small);

        + li {
          border-top: 1px solid var(--color-tertiary);
        }
      }
    }
  }

  h3 {
    font-size: var(--fs-body);
    font-family: var(--ff-secondary);
    font-weight: 600;
    padding: 0 20px;
    margin: 15px 0;
  }

  h4 {
    font-size: var(--fs-small);
    font-family: var(--ff-secondary);
    font-weight: 600;
    margin: 0;
  }

  .camera-wrapper {
    background-color: var(--color-surface);
    width: 100%;
    box-shadow: 0px 3px 18px 5px rgba(0, 0, 0, 0.25);
    border-radius: 20px;
    overflow: hidden;
    margin-top: 30px;

    .scanner-container {
      position: relative;
      overflow: hidden;
      display: flex;

      video {
        width: 100%;
        box-sizing: border-box;
        border-radius: 20px;
      }

      canvas {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
      }
    }
  }

  .in-pending {
    display: grid;
    grid-template-columns: 1fr 20px;
    gap: 0 20px;
    margin: 30px 0;

    .badges {
      span {
        background-color: var(--color-secondary);
        display: block;
        color: var(--color-default-secondary);
        border-radius: 50%;
        height: 20px;
        width: 20px;
        display: grid;
        place-items: center;
        font-weight: 600;
        font-size: var(--fs-caption);
      }
    }
  }
}

.scanner-wrapper {
  padding-bottom: 100px;
  flex-direction: column;
}

.process {
  iframe {
    width: 100%;
  }
}
