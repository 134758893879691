.super-select-label {
  background-color: var(--color-tertiary-light);
  border-radius: 10px;
  font-weight: normal;
  padding: 4px 10px 0;
  small {
    letter-spacing: 0.2px;
    font-size: var(--fs-tiny);
    text-transform: uppercase;
    color: var(--color-tertiary-dark);
    font-weight: 600;
    display: block;
  }
}
