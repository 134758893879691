@import 'breakpoint-slicer';
@import '@assets/scss/breakpoints.scss';
@import '@assets/scss/extend.scss';

.transfer-types-cell-renderer-wrapper {
  list-style: none;
  margin: 0;
  padding: 0;
  display: inline-block;
  p {
    color: var(--color-default-secondary);
    display: inline-block;
    line-height: normal;
    border-radius: 35px;
    padding: 2px 10px;
    text-transform: uppercase;
    font-weight: 600;
    font-size: var(--fs-caption);

    & + p {
      margin-left: 5px;
    }

    &.sale {
      background-color: var(--color-surface-senary);
      color: var(--color-quaternary);
      span {
        &:before {
          content: '💳';
          margin-right: 5px;
        }
      }
    }

    &.aircon-upgrade {
      background-color: var(--color-surface-octonary);
      color: var(--color-quinary);
      span {
        &:before {
          margin-right: 5px;
          content: '✨';
        }
      }
    }

    &.on-site {
      background-color: var(--color-surface-quinary);
      color: var(--color-default-tertiary);
      span {
        &:before {
          margin-right: 5px;
          content: '📍';
        }
      }
    }

    &.brandswitch {
      background-color: var(--color-surface-quinary);
      color: var(--color-default-tertiary);
      span {
        &:before {
          margin-right: 5px;
          content: '🏷️';
        }
      }
    }

    &.new {
      background-color: var(--color-surface-octonary);
      color: var(--color-quinary);
      span {
        &:before {
          margin-right: 5px;
          content: '🆕';
        }
      }
    }

    &.commercial-amendment {
      background-color: var(--color-surface-secondary);
      color: var(--color-primary);
      span {
        &:before {
          margin-right: 5px;
          content: ' ';
        }
      }
    }

    &.unrental {
      background-color: var(--color-surface-quinary);
      color: var(--color-default-tertiary);
      span {
        &:before {
          content: '🔓';
          margin-right: 5px;
        }
      }
    }

    &.deck-renewal {
      background-color: var(--color-surface-octonary);
      color: var(--color-quinary);
      span {
        &:before {
          content: '🛠️';
          margin-right: 5px;
        }
      }
    }

    &.deck-upgrade {
      background-color: var(--color-surface-octonary);
      color: var(--color-quinary);
      span {
        &:before {
          content: '✨ ';
        }
      }
    }

    &.refurbishment {
      background-color: var(--color-surface-octonary);
      color: var(--color-quinary);
      span {
        &:before {
          content: '🛋️';
          margin-right: 5px;
        }
      }
    }

    &.site-to-site {
      background-color: var(--color-surface-quinary);
      color: var(--color-default-tertiary);
      span {
        &:before {
          content: '🚚';
          margin-right: 5px;
        }
      }
    }

    &.second-hand {
      background-color: var(--color-surface-septenary);
      color: var(--color-secondary);
      span {
        &:before {
          content: '🤝🏻';
          margin-right: 5px;
        }
      }
    }
  }
}
