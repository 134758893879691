@import 'breakpoint-slicer';
@import '@assets/scss/breakpoints.scss';
@import '@assets/scss/extend.scss';

.main {
  min-height: 100vh;
  padding: 0 0 50px 0;
  transition: all 250ms ease-in-out;
  @include from('medium') {
    padding: 0 20px 0 90px;
  }

  .layout-content {
    padding: 20px;
    box-sizing: border-box;
    background-color: var(--color-body-background);
    position: relative;

    @include from('medium') {
      padding: 30px 0;
      min-height: 100vh;
      border-radius: 50px 50px 0 0;
    }

    &.layout-disabled {
      user-select: none;
      pointer-events: none;
      position: relative;
      overflow: hidden;
      height: 100vh;
      filter: blur(4px);
      &:after {
        content: '';
        position: absolute;
        inset: 0;
        background-color: var(--color-default-secondary);
        opacity: 0.5;
      }
    }
  }
}
