@import 'breakpoint-slicer';
@import '@assets/scss/breakpoints.scss';
@import '@assets/scss/extend.scss';

.scrollToTopButton {
  position: fixed;
  z-index: 10;
  bottom: 80px;
  right: 10px;
  appearance: none;
  background-color: var(--color-primary);
  border: none;
  height: 40px;
  width: 40px;
  border-radius: 5px;
  cursor: pointer;

  @include from('medium') {
    bottom: 60px;
    right: 10px;
  }
}
