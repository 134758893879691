@import 'breakpoint-slicer';
@import '@assets/scss/breakpoints.scss';
@import '@assets/scss/extend.scss';

.main-header {
  min-height: 80px;
  padding: 20px;
  box-sizing: border-box;
  top: 0;
  z-index: 4;
  background-color: var(--color-surface);
  display: grid;
  grid-template-columns: 1fr auto;
  position: relative;
  gap: 20px;
  align-items: center;

  @include from('medium') {
    position: sticky;
    padding: 20px 0;
  }

  &.panel-opened {
    z-index: 13;
  }

  &-current-page {
    display: inline-flex;
    flex-direction: column;
    justify-content: space-between;
    height: 43px;
    margin-right: 20px;
    h1 {
      @extend .h1;
      padding: 0;
      margin: 0;
    }

    small {
      font-size: var(--fs-caption);
      color: var(--color-tertiary-dark);
    }
  }

  &-quick-search {
    display: block;
    position: relative;
    margin-top: 20px;
    width: 100%;
    max-width: 300px;

    @include from('medium') {
      padding-left: 21px;
      display: inline-block;
      margin-top: 0;
      vertical-align: top;
    }

    @include from('medium') {
      &:after {
        content: '';
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 0;
        width: 1px;
        height: 40px;
        background-color: var(--color-tertiary-light);
      }
    }

    > label {
      display: grid;
      grid-template-columns: auto 1fr;
      gap: 10px;

      > div {
        height: 43px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        span {
          font-size: var(--fs-small);
          font-weight: 600;
          display: block;
        }

        input {
          background-color: transparent;
          color: var(--color-default);
          border: none;
          outline: 0;
          font-size: var(--fs-caption);
          text-transform: uppercase;
          font-family: var(--ff-primary);

          &::placeholder {
            font-family: var(--ff-primary);
            text-transform: none;
            color: var(--color-tertiary-dark);
          }
        }
      }
    }

    .suggestions {
      position: absolute;
      left: 0;
      bottom: -20px;
      transform: translateY(100%);
      z-index: 1;
      background-color: var(--color-surface);
      border-radius: 0 0 10px 10px;
      padding: 10px 20px;
      box-sizing: border-box;
      border-top: 1px solid var(--color-tertiary-light);

      h3 {
        font-size: var(--fs-h3);
        font-weight: 600;
        margin: 0 0 20px;
        color: var(--color-primary);
      }

      ul {
        padding: 0;
        margin: 0;
        list-style: none;
        display: grid;
        grid-template-columns: repeat(3, auto);
        gap: 10px;
        align-items: center;
      }

      li {
        padding: 0;
        button {
          font-size: var(--fs-small);
          background-color: transparent;
          border: 0;
          background-color: var(--color-surface-secondary);
          border-radius: 5px;
          padding: 10px;
          transition: background-color 250ms ease-out;
          cursor: pointer;
          color: var(--color-default);

          &:hover {
            background-color: var(--color-surface-quaternary);
          }
        }
      }
    }
  }

  .main-header-info {
    display: flex;
    gap: 10px;

    .main-header-avatar {
      padding: 0;
      width: 40px;
      margin: 0;
      height: 40px;
      overflow: hidden;
      border-radius: 10px;

      img {
        width: 100%;
      }
    }
  }
}
