@import 'breakpoint-slicer';
@import '@assets/scss/breakpoints.scss';
@import '@assets/scss/extend.scss';

.content-container-header-item {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  .setting-button-wrapper {
    small {
      font-size: var(--fs-setting);
      display: block;
      font-family: var(--ff-primary);
      text-align: center;
    }

    > div {
      display: flex;
      gap: 10px;
    }
  }
}
