@import 'breakpoint-slicer';
@import '@assets/scss/breakpoints.scss';
@import '@assets/scss/extend.scss';

.image-list {
  display: grid;
  grid-template-columns: 1fr;
  box-sizing: border-box;
  gap: 20px;

  &-item {
    background-color: var(--color-surface);
    display: grid;
    box-sizing: border-box;
    gap: 10px;
    border-radius: 5px;
    grid-template-columns: auto 1fr;
    align-items: center;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
    padding: 10px;

    figure {
      background-color: var(--color-surface);
      display: inline-block;
      border-radius: 5px;
      width: 40px;
      height: 40px;
      display: grid;
      place-items: center;
      margin: 0;
      padding: 0;

      img {
        width: 100%;
      }
    }
    p {
      margin: 0;
      color: var(--color-tertiary-dark);
      font-size: var(--fs-small);

      span {
        color: var(--color-default);
        font-weight: 500;
        vertical-align: middle;
        font-size: var(--fs-body);
        display: block;
      }
    }
  }
}
