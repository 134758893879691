@import 'breakpoint-slicer';
@import '@assets/scss/breakpoints.scss';
@import '@assets/scss/extend.scss';

.panel-button {
  background-color: var(--color-surface);
  outline: 0;
  border: 0;
  border-radius: 5px;
  padding: 20px;
  display: grid;
  grid-template-columns: 40px auto;
  gap: 20px;

  &.disabled {
    opacity: 0.5;
  }

  figure {
    margin: 0;
    padding: 0;
    place-self: center;
  }

  p {
    place-self: start;
    text-align: left;
    color: var(--color-tertiary-dark);
    font-size: var(--fs-small);

    span {
      font-weight: 600;
      color: var(--color-default);
      display: block;
      margin-bottom: 5px;
    }
  }
}
