@import 'breakpoint-slicer';
@import '@assets/scss/breakpoints.scss';
@import '@assets/scss/extend.scss';

.connected-users-wrapper {
  background-color: var(--color-surface-tertiary);
  border-radius: 20px;
  box-sizing: border-box;

  .connected-users {
    display: grid;
    margin: 20px 0 0;
    grid-template-columns: repeat(auto-fill, 40px);
    gap: 10px;
  }

  .connected-users-content {
    padding: 0 30px 30px 30px;
  }

  .no-users {
    color: var(--color-default-tertiary);
    font-size: var(--fs-small);
  }
}
