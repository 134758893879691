@import 'breakpoint-slicer';
@import '@assets/scss/breakpoints.scss';
@import '@assets/scss/extend.scss';

.pnf-wrapper {
  display: grid;
  place-items: center;
  height: calc(100vh - 60px);

  .pnf-content {
    max-width: 500px;
  }

  p {
    margin: 0;
    padding: 0;
  }

  .pnf-oversize {
    font-weight: 600;
    font-size: var(--fs-oversize);
    font-family: var(--ff-secondary);
    position: relative;

    span {
      z-index: 1;
      position: relative;
    }

    &:after {
      content: '';
      position: absolute;
      left: -80px;
      bottom: 20px;
      width: 170px;
      height: 170px;
      background-color: var(--color-secondary);
      border-radius: 50%;
    }
  }

  .pnf-warning {
    font-weight: 600;
    font-size: var(--fs-big-size);
    font-family: var(--ff-secondary);
  }

  .pnf-message {
    margin: 10px 0 50px;
    line-height: 25px;
  }

  button {
    margin: 0 auto;
  }
}
