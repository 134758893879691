.redirection-button {
  appearance: none;
  outline: 0;
  background-color: transparent;
  border: none;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: var(--fs-small);
  gap: 10px;
  cursor: pointer;
  padding: 0;

  .icon-wrapper {
    height: 30px;
    width: 30px;
    background-color: var(--color-surface-secondary);
    border-radius: 5px;
    display: grid;
    place-items: center;
  }

  span {
    color: var(--color-primary);
    font-family: var(--ff-primary);
  }
}
