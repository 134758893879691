@import 'breakpoint-slicer';
@import '@assets/scss/breakpoints.scss';
@import '@assets/scss/extend.scss';

.login-section {
  width: 100%;
  min-height: 100vh;
  position: relative;
  display: flex;

  @include from(medium) {
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;

    &:after {
      content: '';
      position: absolute;
      inset: 0;
      background-color: rgba(0, 0, 0, 0.15);
    }
  }

  .login-container {
    position: relative;
    z-index: 1;
    min-height: 100%;
    width: 100%;
    padding: 50px 20px 30px;
    box-sizing: border-box;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    background-color: var(--color-surface);

    @include from(medium) {
      width: 40%;
      border-radius: 0 50px 50px 0;
      padding: 50px 30px 25px;
    }

    &:before {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      height: 100%;
    }
  }

  .grid-item {
    display: grid;
    place-items: center;
    place-self: center;
    width: 100%;
  }

  .wrapper {
    width: 100%;
    position: relative;
    z-index: 1;
    margin: 50px auto 0;

    @include from(medium) {
      width: 350px;
      padding: 20px;
      margin: 0 auto;
    }
  }

  .form-title {
    font-size: var(--fs-small);
    font-weight: bold;
    margin: 0 0 30px;
    letter-spacing: 0.2px;

    @include from(medium) {
      color: var(--color-default);
    }
  }

  label {
    // @extend .input-1;
    background-color: var(--color-tertiary-light);
    display: inline-block;
    width: 100%;
    height: 50px;
    display: flex;
    box-sizing: border-box;
    flex-direction: column;
    justify-content: space-between;
    padding: 5px 10px;
    border-radius: 10px;

    > span {
      font-size: var(--fs-tiny);
      text-transform: uppercase;
      color: var(--color-tertiary-dark);
      display: block;
    }

    > input {
      width: 100%;
      box-sizing: border-box;
      border: none;
      background-color: transparent;
      font-size: var(--fs-small);
      outline: none;
      color: var(--color-default);
    }

    & + label {
      margin: 10px 0 0;
      display: block;
    }
  }

  form button[type='submit'] {
    @extend .button-1;
    width: 100%;
    margin-top: 20px;
  }

  small {
    font-size: var(--fs-caption);
    text-align: center;
    margin: 30px 0 0;
    display: block;
    position: relative;
    z-index: 1;
    color: var(--color-tertiary-dark);

    @include from(medium) {
      position: absolute;
      bottom: 20px;
      left: 50%;
      width: 100%;
      transform: translateX(-50%);
    }
  }
}
