@import 'breakpoint-slicer';
@import '@assets/scss/breakpoints.scss';
@import '@assets/scss/extend.scss';

.transfers-reminder-container {
  background-color: var(--color-surface);
  color: var(--color-default);
  max-height: 400px;
  overflow: auto;
  box-sizing: border-box;
  border-radius: 20px;

  .wrapper {
    padding: 0 10px;

    @include from('medium') {
      padding: 0 30px 30px;
    }

    ul {
      margin: 0;
      padding: 20px 0;
      list-style: none;
      .transfer {
        cursor: pointer;
        position: relative;
        padding: 10px 20px;
        box-sizing: border-box;
        border-radius: 4px;
        background-color: var(--color-surface-quaternary);
        border-color: var(--color-surface-secondary);
        border-width: 1px;
        border-style: solid;
        transition: background-color 200ms ease-out;

        & + .transfer {
          margin-top: 15px;
        }

        &:hover {
          background-color: var(--color-surface-secondary);
          .progress .progress-bar .bar {
            border-top-color: var(--color-tertiary);
          }
        }

        &-detailed-content {
          padding: 10px 0;
          display: grid;
          grid-template-columns: 1fr auto;
          gap: 15px;

          .transfer-detailed-barcode {
            h3 {
              font-size: var(--fs-body);
              margin: 0;
              color: var(--color-primary);
              text-align: right;
            }
          }

          p {
            margin: 0;
            font-size: var(--fs-small);

            & + p {
              margin-top: 10px;
            }
          }
        }

        .progress {
          margin-top: 15px;
          width: 100%;

          &-bar {
            display: grid;
            grid-template-columns: 24px 1fr;
            gap: 2px;
            align-items: center;

            .bar {
              width: 100%;
              border-top-color: var(--color-tertiary-light);
              border-top-width: 1px;
              border-top-style: solid;
              position: relative;
              top: 8px;

              .truck {
                position: absolute;
                top: 7px;
                transform: translateY(-100%);
                width: 40px;
                cursor: help;
              }
            }
          }

          .transfer-date {
            font-size: var(--fs-small);
            color: var(--color-tertiary-dark);

            &.expired {
              color: var(--color-alert-level-4);
            }
          }
        }
      }
    }
  }

  .reloading {
    margin: 20px 0;
  }
}
