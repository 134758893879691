@import 'breakpoint-slicer';
@import '@assets/scss/breakpoints.scss';
@import '@assets/scss/extend.scss';

.icon-list {
  white-space: nowrap;
  overflow: auto;
  width: 100%;
  scroll-snap-type: x mandatory;
  &-item {
    width: 130px;
    padding: 5px 0 10px 0;
    border-radius: 5px;
    background-color: var(--color-surface);
    display: inline-block;

    + .icon-list-item {
      margin-left: 20px;
    }

    &-icon {
      height: 50px;
      width: 50px;
      border-radius: 5px;
      background-color: #cee5d0;
      margin: 0 auto;
      display: grid;
      place-items: center;

      svg {
        width: 100%;
      }

      &.disabled {
        background-color: #bb6464;
      }
    }

    span {
      display: block;
      text-align: center;
      margin-top: 5px;
      font-size: var(--fs-small);
    }
  }
}
