@import 'breakpoint-slicer';
@import '@assets/scss/breakpoints.scss';
@import '@assets/scss/extend.scss';

.pwa-main-footer {
  position: fixed;
  bottom: 0;
  height: 60px;
  width: 100%;
  left: 0;
  background-color: var(--color-surface);
  z-index: 2;
  border-top: 1px solid var(--color-tertiary-dark);
  display: grid;
  grid-template-columns: 1fr 1fr;
  place-items: center;

  .pwa-menu-item {
    &.active {
      small {
        color: var(--color-primary);
      }
    }

    svg {
      margin: 0 auto;
      display: block;
      height: 20px;
    }

    small {
      display: block;
      color: var(--color-tertiary-dark);
      text-align: center;
    }
  }
}
