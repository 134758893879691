.link-cell-renderer {
  padding: 4px 6px;
  background-color: var(--color-primary);
  color: var(--color-default-secondary);
  text-decoration: none;
  font-size: var(--fs-caption);
  text-transform: uppercase;
  font-family: var(--ff-primary);
  font-weight: 600;
  letter-spacing: 0.2px;
  border-radius: 5px;

  &-current {
    font-style: italic;
    display: inline-block;
    cursor: not-allowed;
    font-size: var(--fs-caption);
    letter-spacing: 0.2px;
  }
}
