.campsite-key-figures {
  padding: 0;
  margin: 0;
  list-style: none;

  li {
    padding: 5px 10px;
    box-sizing: border-box;
    margin: 0;
    background-color: var(--color-surface-quaternary);
    display: grid;
    grid-template-columns: 40px 1fr auto;
    gap: 10px;
    border-radius: 10px;
    align-items: center;

    + li {
      margin-top: 5px;
    }

    figure {
      padding: 0;
      margin: 0;
      img {
        width: 100%;
      }
    }

    div {
      font-size: var(--fs-small);
      p {
        margin: 0;
        padding: 0;
        font-weight: 600;
      }
      small {
        color: var(--color-tertiary-dark);
        font-size: var(--fs-caption);
      }
      span {
        font-weight: 600;
        display: inline-block;
        margin-right: 5px;
        + svg {
          vertical-align: top;
        }
      }
    }
  }
}
