@import 'breakpoint-slicer';
@import '@assets/scss/breakpoints.scss';
@import '@assets/scss/extend.scss';

.unit-history {
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 30px 0;
  box-sizing: border-box;
  background-color: var(--color-body-background);
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.25);
  overflow: auto;
  padding: 30px;

  .unit-history-wrapper {
    margin-top: 30px;
    margin-bottom: 50px;

    .carousel-history-spacing {
      + .carousel-history-spacing {
        margin-top: 20px;
      }
    }
  }
}
