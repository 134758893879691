@import 'breakpoint-slicer';
@import '@assets/scss/breakpoints.scss';
@import '@assets/scss/extend.scss';
@import '../../node_modules/tour/dist/tour.css';

html,
body {
  margin: 0;
  padding: 0;
  width: 100%;
  background-color: var(--color-surface);
  color: var(--color-default);
  font-size: var(--fs-body);
  font-family: var(--ff-primary);
  scroll-behavior: smooth;
}

html[data-theme='dark']:root {
  button {
    svg {
      path {
        fill: var(--color-default);
      }
    }
  }
}

html[prefers-expanding-menu='enabled'] {
  .layout {
    @include from('medium') {
      padding: 0 20px 0 200px;

      .menu {
        width: 200px;
        background-color: var(--color-surface);

        .menu-item {
          display: grid;
          grid-template-columns: 30px auto;
          justify-content: start;

          &-label {
            display: inline-block;
            align-self: center;
            justify-self: start;
            padding: 0 10px;
            white-space: nowrap;
          }
        }
      }
    }
  }

  .tippy-menu {
    display: none;
  }
}

body {
  overscroll-behavior-y: contain;
  @include from('medium') {
    overscroll-behavior-y: unset;
  }

  &.disabled-scrolling {
    overflow: hidden;
  }
}

* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
