@import 'breakpoint-slicer';
@import '@assets/scss/breakpoints.scss';
@import '@assets/scss/extend.scss';

.open-file-field {
  box-sizing: border-box;
  margin: 0 8px 8px 8px;
  .model-plan-button {
    display: inline-block;
    text-decoration: none;
    width: 100%;
    background-color: var(--color-primary) !important;
    border-radius: 2px;
    color: var(--color-default-secondary) !important;
    font-size: var(--fs-small);
    text-align: center;
    padding: 5px 0;
    transition: all 250ms ease-out;

    &:hover,
    &:active {
      background-color: var(--color-primary-dark) !important;
    }
  }

  p {
    margin: 0;
    small {
      font-size: var(--fs-small);
    }
  }
}
