.render-field-wrapper {
  margin-top: 15px;
  .render-field {
    background-color: var(--color-tertiary-light);
    border-radius: 10px;
    overflow: hidden;
    font-weight: normal;
    padding: 4px 10px 0;

    &-disabled {
      background-color: transparent;
    }

    small {
      letter-spacing: 0.2px;
      font-size: var(--fs-tiny);
      text-transform: uppercase;
      color: var(--color-tertiary-dark);
      font-weight: 600;
      display: block;
    }

    input,
    textarea {
      color: var(--color-default);
      font-size: var(--fs-small);
      font-family: var(--ff-primary);

      &:disabled {
        cursor: not-allowed;
        color: var(--color-default);
        -webkit-text-fill-color: var(--color-default);
      }
    }
  }

  span {
    font-size: var(--fs-small);
    -webkit-text-fill-color: var(--color-default);
  }
}
