.language-suggestion {
  text-transform: uppercase;
  font-family: var(--ff-primary);
  border: none;
  outline: none;
  letter-spacing: 0.2px;
  font-size: var(--fs-small);
  color: var(--color-default);
  padding: 0 5px;
  background-color: var(--color-surface);
  cursor: pointer;
}
