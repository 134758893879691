.date-picker {
  margin: 15px 0;

  .render-field {
    background-color: var(--color-tertiary-light);
    border-radius: 10px;
    overflow: hidden;
    font-weight: normal;
    padding: 4px 10px 0;

    &-disabled {
      background-color: transparent;
    }

    small {
      letter-spacing: 0.2px;
      font-size: var(--fs-tiny);
      text-transform: uppercase;
      color: var(--color-tertiary-dark);
      font-weight: 600;
      display: block;
    }

    input {
      color: var(--color-default);
      font-size: var(--fs-small);
      font-family: var(--ff-primary);
      appearance: none;
      border: none;
      background-color: transparent;
      width: 100%;
      outline: 0;
      min-height: 38px;

      &:disabled {
        cursor: not-allowed;
        color: var(--color-default);
        -webkit-text-fill-color: var(--color-default);
      }
    }
    .calendar-style {
      font-family: inherit;
      font-size: var(--fs-small);
      z-index: 1;
      position: relative;
    }
  }
}
