@import 'breakpoint-slicer';
@import '@assets/scss/breakpoints.scss';
@import '@assets/scss/extend.scss';

.base-button-wrapper {
  background-color: var(--color-surface);
  padding: 15px;
  border-radius: 5px;
  margin-top: 50px;
  position: fixed;
  box-sizing: border-box;
  width: calc(100% - 40px);
  left: 20px;
  bottom: 75px;
  z-index: 1;
  box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.05);

  p {
    font-weight: 600;
    margin: 0 0 15px 0;
    .category {
      color: #8f8f8f;
      display: block;
      font-weight: 400;
      margin-bottom: 5px;
    }
  }

  .base-button {
    background-color: #6e85b7;
    color: var(--color-default-secondary);
    border: none;
    width: 100%;
    padding: 15px;
    border-radius: 10px;
    text-align: center;
    span {
      color: var(--color-default-secondary);
      font-weight: 600;
      display: inline-block;
      margin-left: 10px;
      font-size: var(--fs-body);
    }

    svg {
      height: 16px;
      vertical-align: middle;
    }
  }
}
