@import 'breakpoint-slicer';
@import '@assets/scss/breakpoints.scss';
@import '@assets/scss/extend.scss';

.guide-view {
  position: fixed;
  height: 100%;
  width: 100%;
  background-color: var(--color-body-background);
  top: 0;
  left: 0;
  padding: 30px 0;
  box-sizing: border-box;
  z-index: 2;
  overflow: auto;

  .header-wrapper {
    padding: 0 30px;
  }

  .guide-wrapper {
    position: relative;
  }

  .guide-content {
    position: relative;
    z-index: 1;
    padding: 30px;
    color: var(--color-default);
    line-height: 26px;

    &-title {
      line-height: 26px;
      font-weight: 600;
    }
  }

  figure {
    margin: 0;
    box-sizing: border-box;
    display: grid;
    img {
      height: 200px;
      width: 100%;
      object-fit: cover;
    }
  }
}
