.selection-panel {
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 30px;
  box-sizing: border-box;
  background-color: var(--color-body-background);
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.25);

  .panel-buttons {
    display: grid;
    margin-top: 30px;
    grid-template-columns: 1fr;
    gap: 20px;
  }
}
