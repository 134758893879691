@import 'breakpoint-slicer';
@import '@assets/scss/breakpoints.scss';
@import '@assets/scss/extend.scss';

.timeline-wrapper {
  list-style: none;
  margin: 0;
  padding: 0;

  .timeline-item {
    background-color: var(--color-surface);
    border-radius: 0 0 20px 20px;
    padding: 20px 10px;

    @include from('medium') {
      padding: 30px;
    }

    & + .timeline-item {
      margin-top: 20px;
      border-radius: 20px;
    }

    // Specific for F2M Data
    &.even-item {
      > div:first-child > div:first-child {
        grid-template-columns: 1fr 30%;
        header {
          order: 2;
          img {
            transform: scale(-1, 1);
          }
        }
      }
    }
  }
}
