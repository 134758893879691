@import 'breakpoint-slicer';
@import '@assets/scss/breakpoints.scss';
@import '@assets/scss/extend.scss';

.base-navigation {
  display: block;
  ul {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    gap: 10px 20px;
    position: relative;

    @include from('medium') {
      gap: 40px;
    }

    &:after {
      content: '';
      position: absolute;
      bottom: 0px;
      left: 0;
      width: 100%;
      height: 2px;
      background-color: var(--color-tertiary-light);
    }

    li {
      display: block;

      &.active {
        color: var(--color-primary);

        button {
          border-bottom: 2px solid var(--color-primary);
        }
      }

      button {
        appearance: none;
        outline: 0;
        background: none;
        position: relative;
        z-index: 1;
        border: none;
        font-size: var(--fs-small);
        color: inherit;
        font-family: inherit;
        padding: 10px 0;
        box-sizing: border-box;
        cursor: pointer;
      }
    }
  }
}
