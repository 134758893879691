.no-wrap-navigation {
  width: 100%;
  position: sticky;
  top: -30px;
  z-index: 1;
  box-sizing: border-box;
  ol {
    list-style: none;
    margin: 0;
    display: grid;
    grid-auto-flow: column;
    padding: 30px;
    overflow: auto;
    box-sizing: border-box;
    gap: 20px;

    li {
      display: inline-block;

      button {
        background-color: white;
        border-radius: 5px;
        border: none;
        padding: 5px 15px;
        font-size: var(--fs-body);
        color: var(--color-default);
        font-weight: 600;
        outline: 0;
        box-shadow: 0 0 15px rgba(0, 0, 0, 0.25);

        &.active {
          background-color: #6e85b7;
          color: var(--color-default-secondary);
        }
      }
    }
  }
}
