.camera-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  .scanner-container {
    width: 100%;
    height: 100%;

    video {
      height: 100%;
      width: 100%;
    }
  }

  canvas {
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    transform: translateY(-50%);
  }
}
