@import 'breakpoint-slicer';
@import '@assets/scss/breakpoints.scss';
@import '@assets/scss/extend.scss';

.menu-item {
  background: none;
  border: none;
  padding: 0;
  height: 30px;
  // width: 30px;
  position: relative;
  cursor: pointer;
  border-radius: 5px;
  display: grid;
  grid-template-columns: 30px;

  &.menu-disabled-on-mobile {
    display: none;
    @include from('medium') {
      display: grid;
    }
  }

  svg {
    place-self: center;
    // position: absolute;
    // top: 50%;
    // left: 50%;
    // transform: translate(-50%, -50%);
    path {
      transition: fill 250ms ease-out;
    }

    &:hover {
      path {
        fill: var(--color-primary);
      }
    }

    + span {
      display: none;
      color: var(--color-default);
    }
  }

  &.active,
  &:hover {
    transition: background-color 100ms ease-out;
    background-color: var(--color-surface-secondary);

    svg {
      fill: var(--color-primary);
      path {
        fill: var(--color-primary);
      }
    }
  }
}
