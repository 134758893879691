.menu-sub-item {
  position: relative;

  &.separate {
    margin: 100px 0 0;
  }

  button {
    appearance: none;
    outline: 0;
    background: none;
    border: none;
    cursor: pointer;
    display: block;
    width: 100%;
    text-align: left;
    padding: 15px 60px 15px 30px;
    border-radius: 10px;

    border-color: var(--color-secondary);
    font-size: var(--fs-small);
    font-family: var(--ff-primary);
    color: var(--color-default);
    font-weight: normal;
    background-color: var(--color-surface);

    &:hover {
      font-weight: 600;
    }
  }

  .menu-sub-item-icon {
    pointer-events: none;
    position: absolute;
    width: 20px;
    height: 20px;
    border-radius: 20px;
    background-color: var(--color-secondary);
    right: 30px;
    top: 50%;
    transform: translateY(-50%);

    svg {
      width: 14px;
      height: 14px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
}
