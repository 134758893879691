.connected-user {
  display: inline-block;
  padding: 0;
  margin: 0;
  img {
    width: 40px;
    height: 40px;
    object-fit: cover;
    border-radius: 10px;
  }
  &.copy {
    cursor: pointer;
  }
}
