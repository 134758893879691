@import 'breakpoint-slicer';
@import '@assets/scss/breakpoints.scss';
@import '@assets/scss/extend.scss';

.content-header {
  display: grid;
  grid-template-columns: 40px auto;
  gap: 10px;
  background-color: var(--color-surface-quaternary);
  border-radius: 20px 20px 0 0;
  padding: 20px 10px;

  @include from('medium') {
    padding: 30px;
  }

  .icon-wrapper {
    background-color: var(--color-surface);
    border-radius: 50%;
    height: 43px;
    width: 43px;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
    display: grid;
    place-items: center;
  }

  &.light {
    background-color: transparent;
    h1 {
      color: var(--color-default-tertiary);
    }
    small {
      color: var(--color-default-tertiary);
    }
  }

  h1 {
    @extend .h1;
    font-weight: 400;
    padding: 0;
    color: var(--color-default);
    margin: 0;
  }

  small {
    color: var(--color-tertiary-dark);
    font-size: var(--fs-caption);
  }
}
