@import 'breakpoint-slicer';
@import '@assets/scss/breakpoints.scss';
@import '@assets/scss/extend.scss';

.pending-wrapper {
  .pending-current-section {
    margin: 30px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .pending-pages {
      padding: 5px 10px;
      background-color: var(--color-secondary);
      color: var(--color-default-secondary);
      border-radius: 5px;
      margin: 0 auto;
    }
  }

  .pending-form {
    background-color: var(--color-surface);
    margin-top: 30px;
    padding: 20px;
    border-radius: 5px;

    &-header {
      display: grid;
      grid-template-columns: 40px 1fr;
      gap: 20px;
      align-items: center;
      figure {
        margin: 0;
        padding: 0;
      }
      p {
        margin: 0;
        color: var(--color-tertiary-dark);
        font-size: var(--fs-small);
        span {
          font-family: var(--ff-secondary);
          margin-bottom: 5px;
          font-size: var(--fs-body);
          display: block;
          color: var(--color-default);
        }
      }
    }

    .hidden {
      display: none;
    }

    .pending-field {
      font-weight: 400;
      padding: 20px 0;
      display: block;

      + .pending-field {
        border-top: 1px dashed var(--color-tertiary-dark);
      }

      span {
        font-size: var(--fs-small);
        color: var(--color-tertiary-dark);
        display: grid;
        grid-template-columns: 1fr 20px;
        position: relative;
        align-items: center;

        svg {
          width: 20px;
          place-self: center;
        }
      }

      .pending-input {
        border: none;
        width: 100%;
        outline: 0;
        font-size: var(--fs-body);
        font-weight: 500;
        font-family: inherit;
      }

      .pending-select {
        border: none;
        padding: 0;
        width: 100%;
        background-color: var(--color-surface);
        font-size: var(--fs-body);
        font-weight: 500;
        font-family: inherit;
        margin-top: 5px;
        margin-left: -4px;
      }
    }
  }

  .pending-buttons {
    display: flex;
    justify-content: space-between;
    padding: 30px 0;
    margin-bottom: 30px;

    button {
      border: none;
      background-color: #6e85b7;
      padding: 15px;
      border-radius: 5px;
      color: var(--color-default-secondary);
      font-size: var(--fs-body);

      &:disabled {
        opacity: 0.4;
      }
    }
  }
}
