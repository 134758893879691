.container {
  width: 100%;
  box-sizing: border-box;
  padding: 20px;
  height: 500px;
  position: relative;

  .prices {
    margin-bottom: 40px;
  }

  h3 {
    font-size: var(--fs-h3);
    font-weight: 600;
    margin: 0 0 20px;
    color: var(--color-primary);
  }

  p {
    margin: 0;
    padding: 0;
    display: grid;
    grid-template-columns: 1fr auto;

    &:not(:first-child) {
      margin-top: 10px;
    }

    .tag {
      display: inline-block;
      background-color: var(--color-surface-secondary);
      font-size: var(--fs-small);
      color: var(--color-default);
      padding: 2px 5px;
      border-radius: 4px;
    }
  }
}
