@import 'breakpoint-slicer';
@import '@assets/scss/breakpoints.scss';
@import '@assets/scss/extend.scss';

.campsites-map {
  position: relative;
  width: 100%;
  box-sizing: border-box;
  margin: 20px 0;
  @include from('medium') {
    display: grid;
    grid-template-columns: minmax(200px, 400px) 1fr;
    padding: 30px 0 0;
    margin: 20px 0 0;
    height: auto;
    gap: 0 10px;
  }

  .campsites-list-button {
    margin-bottom: 20px;

    @include from('medium') {
      display: none;
    }
  }

  .list {
    position: absolute;
    height: calc(100vh - 67px);
    overflow: auto;
    z-index: 1;
    background-color: var(--color-surface);
    box-sizing: border-box;
    transform: translateX(-105%);
    max-width: 500px;
    padding: 10px;
    width: 100%;
    @include from('medium') {
      max-width: auto;
      height: auto;
      position: relative;
      overflow: unset;
      background-color: transparent;
      left: auto;
      transform: none;
      padding: 0 10px;
    }

    &.open {
      transform: translateX(0);
      @include from('medium') {
        transform: none;
      }
    }

    label {
      // @extend .input-1;
      background-color: var(--color-tertiary-light);
      display: inline-block;
      width: 100%;
      height: 50px;
      display: flex;
      box-sizing: border-box;
      flex-direction: column;
      justify-content: space-between;
      padding: 5px 10px;
      margin-bottom: 20px;
      border-radius: 10px;

      > span {
        font-size: var(--fs-tiny);
        text-transform: uppercase;
        color: var(--color-tertiary-dark);
        display: block;
      }

      > input {
        width: 100%;
        box-sizing: border-box;
        border: none;
        background-color: transparent;
        font-size: var(--fs-small);
        outline: none;
        color: var(--color-default);
      }
    }

    // input {
    //   height: 40px;
    //   width: 100%;
    //   box-sizing: border-box;
    //   margin-bottom: 20px;
    //   border-radius: 2px;
    //   border: 1px solid var(--color-tertiary);
    //   padding: 0 10px;
    //   position: sticky;
    //   top: 20px;
    //   box-sizing: border-box;
    //   z-index: 1;
    //   outline: 0;

    //   @include from('medium') {
    //     top: 0;
    //     position: relative;
    //   }
    // }

    > ul {
      padding: 0;
      margin: 20px 0 0;
      box-sizing: border-box;
      list-style: none;
      overflow: auto;
      @include from('medium') {
        margin: 0;
        height: calc(100vh - 100px);
      }
      > li {
        box-sizing: border-box;
        width: 100%;
        background-color: var(--color-surface);
        cursor: pointer;
        display: grid;
        border-radius: 10px;

        & + li {
          margin-top: 15px;
        }

        img {
          width: 32px;
        }

        > header {
          h4 {
            @extend .h4;
            margin: 0;
            display: block;
            padding: 10px;
            border-radius: 10px 10px 0 0;
            box-sizing: border-box;
            background-color: var(--color-surface-secondary);
          }
          span {
            color: var(--color-secondary);
            // vertical-align: middle;
          }
          p {
            font-size: var(--fs-small);
            font-weight: 700;
            margin: 0;
          }
        }

        .details {
          padding: 10px;
          box-sizing: border-box;

          .contact-unavailable {
            font-size: var(--fs-small);
          }

          > ul {
            padding: 0;
            margin: 10px 0 0 0;
            list-style: none;

            > li {
              font-size: var(--fs-small);
              padding: 5px 0;

              & + li {
                border-top: 1px solid var(--color-surface-secondary);
                margin-top: 10px;
              }

              > div:first-child {
                font-weight: 700;
                text-align: center;
                display: block;
                margin: 10px auto;

                img {
                  display: block;
                  margin: 0 auto 5px;
                  border-radius: 5px;
                }
              }

              > div:last-child {
                .list-header {
                  background-color: var(--color-surface-secondary);
                  display: inline-block;
                  color: var(--color-primary);
                  font-weight: 600;
                  font-size: var(--fs-caption);
                  padding: 5px 10px;
                  border-radius: 2px;
                }
                ul {
                  list-style: none;
                  padding: 10px;
                  box-sizing: border-box;
                  font-size: var(--fs-small);

                  + ul {
                    border-top: 1px solid var(--color-surface-secondary);
                    margin-top: 10px;
                  }
                  li {
                    line-height: 20px;

                    &.list-title {
                      font-weight: 800;
                      // text-align: left;
                      text-transform: uppercase;
                      margin: 10px 0 0;
                    }
                  }
                }
              }

              div {
                p {
                  margin: 0;
                  line-height: 25px;
                }
              }
            }

            &.rmt {
              background-color: var(--color-surface-quaternary);
              padding: 5px;
              box-sizing: border-box;
              border-radius: 2px;
            }
          }

          .depot {
            text-align: right;
            margin-top: 20px;

            span {
              display: inline-block;
              font-size: var(--fs-caption);
              padding: 5px 10px;
              border-radius: 5px;
              font-weight: 600;

              + span {
                margin-left: 5px;
              }

              &.official {
                color: var(--color-quinary);
                background-color: var(--color-surface-octonary);
              }
              &.in-season {
                color: var(--color-secondary);
                background-color: var(--color-surface-septenary);
              }
            }
          }
        }
      }
    }
  }

  .map {
    outline: 0;
    height: calc(100vh - 67px);
    width: 100%;
    @include from('medium') {
      margin-top: 0;
      height: 100%;
    }
  }
}
