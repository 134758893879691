@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600&family=Sora:wght@400;600&display=swap');
:root {
  --fs-oversize: 100px;
  --fs-big-size: 34px;
  --fs-title: 18px;
  --fs-body: 16px;
  --fs-small: 14px;
  --fs-caption: 12px;
  --fs-tiny: 8px;
  --fs-h1: 16px;
  --fs-h2: 16px;
  --fs-h3: 14px;
  --fs-setting: 12px;
  --fs-switch: 16px;

  --color-primary: #194989;
  --color-primary-dark: #194989;
  --color-secondary: #eec373;
  --color-tertiary-light: #f2f2f2;
  --color-tertiary: #d1d1d1;
  --color-tertiary-dark: #8f8f8f;
  --color-quaternary: #632626;
  --color-quinary: #6d8b74;
  --color-default: #333333;
  --color-default-secondary: #ffffff;
  --color-default-tertiary: #ffffff;

  --color-surface: #ffffff;
  --color-surface-secondary: #daeaff;
  --color-surface-tertiary: #9d7ef1;
  --color-surface-quaternary: #fafbfd;
  --color-surface-quinary: #333333;
  --color-surface-senary: #ff7878;
  --color-surface-septenary: #f5f0bb;
  --color-surface-octonary: #c8dbbe;
  --color-body-background: #f8f8f8;

  --color-alert-level-1: #6d8b74;
  --color-alert-level-2: #eec373;
  --color-alert-level-3: #f6ae99;
  --color-alert-level-4: #ff7878;
  --color-alert-level-5: #9d7ef1;

  --ff-primary: 'Open Sans', sans-serif;
  --ff-secondary: 'Sora', sans-serif;

  --cb-bouncing: cubic-bezier(0.175, 0.885, 0.32, 1.275);

  --max-body-width: 1200px;
}

// Dark theme
html[data-theme='dark']:root {
  --color-primary: #daeaff;
  --color-secondary: #f5f0bb;
  --color-tertiary-light: #252525;
  --color-tertiary: #d1d1d1;
  --color-tertiary-dark: #ffffff;
  --color-quaternary: #ff7878;
  --color-quinary: #c8dbbe;
  --color-default: #ffffff;
  --color-default-secondary: #333333;
  --color-default-tertiary: #ffffff;

  --color-surface: #171717;
  --color-surface-secondary: #194989;
  --color-surface-tertiary: #9d7ef1;
  --color-surface-quaternary: #252525;
  --color-surface-quinary: #333333;
  --color-surface-senary: #632626;
  --color-surface-septenary: #eec373;
  --color-surface-octonary: #6d8b74;
  --color-body-background: #121212;

  --color-alert-level-1: #6d8b74;
  --color-alert-level-2: #eec373;
  --color-alert-level-3: #f6ae99;
  --color-alert-level-4: #ff7878;
  --color-alert-level-5: #9d7ef1;

  --color-ag-grid-header: #21262d;
  --color-ag-grid-row: #21262d;
}

html[data-font-size='big']:root {
  --fs-title: 20px;
  --fs-body: 18px;
  --fs-small: 16px;
  --fs-caption: 14px;
  --fs-tiny: 10px;
  --fs-h1: 18px;
  --fs-h2: 18px;
  --fs-h3: 16px;
  --fs-setting: 14px;
  --fs-switch: 18px;
}
