@import 'breakpoint-slicer';
@import '@assets/scss/breakpoints.scss';
@import '@assets/scss/extend.scss';

.detailed-statistics-scroll {
  .detailed-statistics {
    background-color: var(--color-surface);
    border-radius: 20px;
    box-sizing: border-box;
    .detailed-statistics-wrapper {
      padding: 0 10px 10px;
      box-sizing: border-box;

      @include from('medium') {
        padding: 20px 30px 30px;
      }

      .details-statistics-content {
        margin-top: 20px;
      }
    }
  }
}
