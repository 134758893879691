@import 'breakpoint-slicer';
@import '@assets/scss/breakpoints.scss';
@import '@assets/scss/extend.scss';

.key-figures-wrapper {
  background-color: var(--color-surface);
  border-radius: 20px;
  box-sizing: border-box;

  .key-figures-content {
    margin-top: 20px;
    padding: 0 10px;

    @include from('medium') {
      padding: 0 30px 30px;
    }
  }
}
