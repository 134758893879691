@import 'breakpoint-slicer';
@import '@assets/scss/breakpoints.scss';
@import '@assets/scss/extend.scss';

.data-wrapper {
  margin-top: 20px;
  width: 100%;
  overflow: auto;
  display: grid;
  table,
  th,
  td {
    border: 1px solid var(--color-surface);
    border-collapse: collapse;
    // line-height: 20px;
  }

  table {
    border-radius: 5px;
    width: 100%;
    border-collapse: collapse;
    border-radius: 5px;
    overflow: hidden;
    box-sizing: border-box;
    background-color: var(--color-surface-quaternary);

    thead {
      background-color: var(--color-surface-secondary);
      position: sticky;
      top: 0;

      th {
        font-size: var(--fs-small);
        color: var(--color-default);
        font-weight: 600;
        font-family: var(--ff-secondary);
        padding: 5px 10px;
      }
    }

    tbody {
      overflow: auto;
      box-sizing: border-box;
      tr {
        // background-color: var(--color-surface-tertiary);

        td:first-child {
          p {
            background-color: var(--color-surface-octonary);
            color: var(--color-quinary);
            border-radius: 10px;
            padding: 2px 10px;
            display: inline-block;
            word-break: normal;
          }
        }
        td:nth-child(2) {
          p {
            // background-color: var(--color-surface-octonary);
            // color: var(--color-quinary);
            // border-radius: 10px;
            // padding: 2px 10px;
            // display: inline-block;
            text-transform: uppercase;
            font-weight: 600;
            font-size: var(--fs-caption);
          }
        }
        td:first-child {
          p {
            background-color: var(--color-surface-octonary);
            color: var(--color-quinary);
            border-radius: 10px;
            padding: 2px 10px;
            display: inline-block;
          }
        }
      }
      tr:nth-child(2n) {
        background-color: var(--color-surface);
      }

      td {
        word-break: break-all;
        padding: 10px 5px;
        text-align: center;
        font-size: var(--fs-small);
        p {
          margin: 0;
          padding: 0;

          &.old {
            color: var(--color-quaternary);
          }

          &.moderate {
            color: var(--color-secondary);
          }

          &.recent {
            color: var(--color-quinary);
          }

          &.total {
            background-color: var(--color-surface-secondary);
            border-radius: 10px;
            padding: 2px 10px;
            white-space: nowrap;
            display: inline-block;
          }
        }
      }
    }
  }
}

.data-buttons-wrapper {
  button {
    appearance: none;
    outline: 0;
    border: none;
    background-color: transparent;
    cursor: pointer;
    padding: 10px;
    border-radius: 5px;

    &.active {
      background-color: var(--color-surface-secondary);
    }

    & + button {
      margin-left: 20px;
    }

    img {
      height: 17px;
    }
  }
}

.data-button-extend {
  width: 100%;
  button {
    background-color: var(--color-primary);
    border-radius: 50%;
    height: 30px;
    width: 30px;
    display: grid;
    place-items: center;
    border: 0;
    padding: 0;
    margin: 20px auto 0;
    cursor: pointer;
    transition: all 250ms ease-out, top 100ms;
    top: 0;
    position: relative;

    &:hover {
      top: -3px;
      box-shadow: 0 0 4px var(--color-tertiary-dark);
      transition: all 250ms ease-out;
    }

    &.close {
      transform: rotate(45deg);
    }
  }
}
