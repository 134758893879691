@import 'breakpoint-slicer';
@import '@assets/scss/breakpoints.scss';
@import '@assets/scss/extend.scss';

.flag-cell-renderer {
  img {
    vertical-align: middle;
    display: inline-block;
    margin-right: 5px;
    border-radius: 2px;
    width: 24px;

    + span {
      background-color: var(--color-surface-octonary);
      color: var(--color-quinary);
      border-radius: 10px;
      padding: 2px 10px;
      display: inline-block;
      text-transform: uppercase;
      font-size: var(--fs-caption);
      font-weight: 600;
      line-height: normal;
    }
  }
}
