@import 'breakpoint-slicer';
@import '@assets/scss/breakpoints.scss';
@import '@assets/scss/extend.scss';

.form-section {
  &-base-navigation {
    background-color: var(--color-surface);
    padding: 10px;
    box-sizing: border-box;
    border-top: 1px solid var(--color-tertiary-light);
    border-radius: 0 0 20px 20px;

    @include from('medium') {
      padding: 40px 30px 10px;
      position: sticky;
      z-index: 2;
      top: 84px;
    }
  }

  .form {
    margin: 20px 0 0;
    display: grid;
    gap: 20px;
    grid-template-columns: 1fr;

    @include from('medium') {
      grid-template-columns: 1fr auto;
    }

    .form-actions {
      order: 1;
      position: sticky;
      top: 10px;

      @include from('medium') {
        order: 2;
        position: relative;
        top: auto;
      }

      &-wrapper {
        border-radius: 10px;
        background-color: var(--color-surface);
        padding: 20px 10px;
        box-sizing: border-box;
        display: flex;
        flex-wrap: wrap;
        gap: 10px 20px;

        @include from('medium') {
          position: sticky;
          top: 195px;
          flex-direction: column;
          gap: 20px;
        }
      }
    }

    .content-container {
      display: block;
      @include from('medium') {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 20px;
      }
    }

    .form-input-block {
      border-radius: 30px 30px 20px 20px;
      background-color: var(--color-surface);

      > div {
        position: relative;
        border-top: 1px solid var(--color-tertiary-light);
        padding: 20px;
        border-radius: 0 0 20px 20px;
        label {
          display: block;
          // grid-template-columns: 40px 1fr;
          margin: 0;

          > span {
            display: inline-block;
            width: auto;

            & + span {
              color: var(--color-default);
            }
          }
        }

        .input-form {
          // @extend .input-1;
          margin-top: 15px;
          background-color: var(--color-tertiary-light);
          display: inline-block;
          width: 100%;
          display: flex;
          box-sizing: border-box;
          flex-direction: column;
          justify-content: space-between;
          padding: 5px 10px;
          border-radius: 10px;

          > span {
            font-size: var(--fs-tiny);
            text-transform: uppercase;
            color: var(--color-tertiary-dark);
            display: block;
          }

          > input,
          textarea {
            width: 100%;
            box-sizing: border-box;
            border: none;
            background-color: transparent;
            font-size: var(--fs-small);
            outline: none;
            color: var(--color-default);
          }
        }
      }
    }
  }
}
