@import 'breakpoint-slicer';
@import '@assets/scss/breakpoints.scss';
@import '@assets/scss/extend.scss';

.main {
  min-height: 100vh;
  box-sizing: border-box;
  padding: 20px 10px;
  @include from('medium') {
    padding: 0 0 0 70px;
  }

  .layout-content {
    box-sizing: border-box;
    @include from('medium') {
      padding: 30px 10px 20px;
      background-position: center bottom;
      background-repeat: no-repeat;
      background-size: 100% auto;
      background-attachment: fixed;
      min-height: 100vh;
    }

    &.layout-disabled {
      user-select: none;
      pointer-events: none;
      position: relative;
      overflow: hidden;
      height: 100vh;
      filter: blur(4px);
      &:after {
        content: '';
        position: absolute;
        inset: 0;
        background-color: var(--color-default-secondary);
        opacity: 0.5;
      }
    }
  }

  footer {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 60px;
    background-color: var(--color-surface);
    box-sizing: border-box;
    border-top: 1px solid var(--color-tertiary);
    display: grid;
    grid-template-columns: 1fr 40px;
    align-items: center;
    padding: 0 20px;
  }
}
