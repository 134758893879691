@import 'breakpoint-slicer';
@import '@assets/scss/breakpoints.scss';
@import '@assets/scss/extend.scss';

.form-section {
  &-base-navigation {
    background-color: var(--color-surface);
    padding: 10px;
    box-sizing: border-box;
    border-top: 1px solid var(--color-tertiary-light);
    border-radius: 0 0 20px 20px;

    @include from('medium') {
      padding: 40px 30px 10px;
      position: sticky;
      z-index: 2;
      top: 84px;
    }
  }
}

.form {
  margin: 20px 0 0;
  display: grid;
  grid-template-columns: 1fr;

  &.edition {
    gap: 20px;
    @include from('medium') {
      grid-template-columns: 1fr auto;
    }
  }

  form {
    order: 2;
    @include from('medium') {
      order: 1;
    }
  }

  .no-report {
    font-size: var(--fs-small);
  }

  .form-actions {
    order: 1;
    position: sticky;
    top: 10px;

    @include from('medium') {
      order: 2;
      position: relative;
      top: auto;
    }
    &-wrapper {
      border-radius: 10px;
      background-color: var(--color-surface);
      padding: 20px 10px;
      box-sizing: border-box;
      display: flex;
      flex-wrap: wrap;
      gap: 10px 20px;

      @include from('medium') {
        position: sticky;
        top: 195px;
        flex-direction: column;
        gap: 20px;
      }
    }
  }

  .content-container {
    grid-gap: 20px;

    > div {
      &.hide {
        visibility: hidden;
        position: absolute;
        top: -9999px;
        pointer-events: none;
        z-index: -1;
      }

      &.display {
        display: grid;
        grid-template-columns: 1fr;
        gap: 20px;
        @include from('medium') {
          grid-template-columns: repeat(2, 1fr);
        }
      }

      &.grid-one-item {
        @include from('medium') {
          grid-template-columns: 1fr;
        }
      }
    }
  }

  .form-input-block {
    border-radius: 30px 30px 20px 20px;

    > div {
      position: relative;
      border-top: 1px solid var(--color-tertiary-light);
      background-color: var(--color-surface);
      border-radius: 0 0 20px 20px;
      padding: 20px 10px;

      @include from('medium') {
        padding: 20px;
      }

      label {
        display: block;
        // grid-template-columns: 40px 1fr;
        margin: 0;

        > span {
          display: inline-block;
          width: auto;

          & + span {
            color: var(--color-default);
          }
        }
      }
    }
  }
}
