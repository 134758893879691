.super-select-label {
  background-color: var(--color-tertiary-light);
  border-radius: 10px;
  font-weight: normal;
  padding: 4px 10px 0;
  position: relative;

  &:before {
    content: '📜';
    position: absolute;
    right: 15px;
    top: 50%;
    transform: scale(-1, 1) translateY(-50%);
    width: 20px;
    height: 20px;
  }

  small {
    letter-spacing: 0.2px;
    font-size: var(--fs-tiny);
    text-transform: uppercase;
    color: var(--color-tertiary-dark);
    font-weight: 600;
    display: block;
  }

  select {
    appearance: none;
    border: 0;
    color: var(--color-default);
    font-family: inherit;
    width: 100%;
    padding: 5px;
    font-size: var(--fs-small);
    background-color: transparent;
    cursor: pointer;
    margin: 5px 0;
    height: 30px;

    &:focus {
      outline: 0;
    }

    option {
      font-size: var(--fs-small);
      padding: 10px 0;
    }
  }
}
