@import 'breakpoint-slicer';
@import '@assets/scss/breakpoints.scss';
@import '@assets/scss/extend.scss';

.toggle {
  appearance: none;
  border: none;
  outline: none;
  background-color: transparent;
  padding: 5px;
  display: inline-block;
  cursor: pointer;

  & + .toggle {
    @include from('medium') {
      margin-top: 20px;
    }
  }

  &.active {
    background: var(--color-surface);
    box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, 0.1);
    border-radius: 50%;
    height: 30px;
    width: 30px;
  }
}
