@import 'breakpoint-slicer';
@import '@assets/scss/breakpoints.scss';
@import '@assets/scss/extend.scss';

.grid-view-popin {
  position: fixed;
  bottom: 0;
  right: 0;
  z-index: 10;
  width: calc(100% - 70px);
  background-color: var(--color-surface);
  padding: 20px;
  box-sizing: border-box;
  border-top: 1px solid var(--color-tertiary-light);

  &-wrapper {
    max-width: var(--max-body-width);
    margin: 0 auto;
    display: grid;
    grid-template-columns: 30% 1fr;
    gap: 20px;

    figure {
      padding: 0;
      margin: 0;
      border-radius: 20px;
      background-color: var(--color-surface-quaternary);
      display: grid;
      place-items: center;
      img {
        width: 100%;
        max-height: 250px;
      }
    }
  }

  h2 {
    margin: 0 0 20px;
    padding: 0;
    font-size: var(--fs-h2);
  }

  .grid {
    display: grid;
    grid-template-columns: 1fr auto;
    gap: 15px;

    & + .grid {
      margin-top: 15px;
    }
  }

  small {
    font-weight: 600;
    margin: 20px 0 10px;
    display: block;
  }

  select,
  input {
    height: 55px;
    border-radius: 10px;
    width: 100%;
    box-sizing: border-box;
    border: none;
    font-size: var(--fs-small);
    outline: none;
    color: var(--color-default);
    background-color: var(--color-tertiary-light);
    padding: 5px 10px;
    &:disabled {
      cursor: not-allowed;
    }
  }

  label {
    // @extend .input-1;
    background-color: var(--color-tertiary-light);
    display: inline-block;
    width: 100%;
    height: 55px;
    display: flex;
    box-sizing: border-box;
    flex-direction: column;
    justify-content: space-between;
    padding: 5px 10px;
    border-radius: 10px;

    > span {
      font-size: var(--fs-tiny);
      text-transform: uppercase;
      color: var(--color-tertiary-dark);
      display: block;
    }

    > input {
      width: 100%;
      box-sizing: border-box;
      border: none;
      background-color: transparent;
      font-size: var(--fs-small);
      outline: none;
      color: var(--color-default);
    }

    & + label {
      margin: 10px 0 0;
      display: block;
    }
  }

  .grid-buttons {
    margin-top: 20px;
    display: flex;
    justify-content: flex-start;
    gap: 20px;

    .cancel-button {
      background-color: transparent;
      border: none;
      text-decoration: underline;
      font-family: var(--ff-primary);
      font-size: var(--fs-small);
      padding: 5px 10px;
      cursor: pointer;
    }
  }
}
