@import 'breakpoint-slicer';
@import '@assets/scss/breakpoints.scss';
@import '@assets/scss/extend.scss';

.key-figure {
  display: grid;
  grid-template-columns: auto auto auto;
  background-color: var(--color-surface-quaternary);
  border-radius: 10px;
  padding: 10px;
  gap: 10px;
  text-transform: capitalize;

  @include from('medium') {
    gap: 20px;
  }

  & + .key-figure {
    margin-top: 5px;
  }

  > figure {
    padding: 0;
    margin: 0;
    grid-template-columns: 40px auto;
    gap: 10px;
    display: grid;

    img {
      width: 40px;
      place-self: center;
    }
    figcaption {
      color: var(--color-tertiary-dark);
      font-size: var(--fs-small);
      h4 {
        @extend .h4;
        margin: 0;
      }
    }
  }
  > h1 {
    @extend .h1;
    text-shadow: 0px 2px 3px rgba(0, 0, 0, 0.25);
    text-align: center;
    margin: 0;
  }

  .key-figure-rental-value,
  .key-figure-total-value {
    font-weight: 600;
    align-self: center;
    justify-self: end;

    svg {
      vertical-align: text-top;
      margin-left: 5px;
    }
  }
}
