@import 'breakpoint-slicer';
@import '@assets/scss/breakpoints.scss';
@import '@assets/scss/extend.scss';

.ag-theme-alpine {
  font-family: var(--ff-primary);
  --ag-foreground-color: var(--color-default);
  --ag-input-disabled-background-color: var(--color-surface);
  --ag-control-panel-background-color: var(--color-surface);
  // Border
  --ag-border-color: var(--color-surface);
  --ag-secondary-border-color: var(--color-surface);

  // Background
  --ag-background-color: var(--color-surface-quaternary);

  // Row
  --ag-odd-row-background-color: var(--color-surface);

  // Header
  --ag-header-background-color: var(--color-tertiary-light);
  // Cell
  --ag-font-size: var(--fs-caption);

  // Panel
  --ag-control-panel-background-color: var(--color-tertiary-light);
  --ag-selected-tab-underline-color: var(--color-primary);
  --ag-subheader-background-color: var(--color-tertiary-light);

  .ag-side-buttons {
    background-color: var(--color-tertiary-light);
  }
  .ag-side-buttons {
    background-color: var(--color-tertiary-light);
  }

  .ag-header-cell-text,
  .ag-header-group-text {
    // font-weight: normal;
    font-size: var(--fs-small);
    text-transform: capitalize;
  }

  .ag-row {
    font-size: var(--fs-small);
  }

  .ag-overlay-wrapper {
    background-color: var(--color-surface);
  }

  .ag-cell {
    &:hover {
      background-color: var(--color-surface-septenary);
    }
  }

  .ag-overlay-center {
    font-size: var(--fs-small);
    color: var(--color-primary);
    font-weight: 600;
    background-color: var(--color-surface-secondary);
    padding: 5px 10px;
    border-radius: 5px;
  }

  .ag-side-button {
    .ag-side-button-button {
      border: none !important; // ! Not recommanded
    }
    &.ag-selected {
      .ag-side-button-button {
        border: none;
        transition: background-color 150ms ease-out;
        background-color: var(--color-surface-secondary);
      }
    }
  }

  .ag-filter-toolpanel-group-container {
    background-color: var(--color-tertiary);
    padding: 5px;
  }
  .ag-header-cell-text,
  .ag-header-group-text {
    // font-weight: normal;
    font-size: var(--fs-small);
    text-transform: capitalize;
  }

  .ag-row {
    font-size: var(--fs-small);
  }

  .ag-overlay-wrapper {
    background-color: var(--color-surface);
  }

  .ag-cell {
    &:hover {
      background-color: var(--color-surface-septenary);
    }
  }
}

#myGrid.ag-theme-alpine {
  .ag-header {
    z-index: 4;
  }

  .ag-root-wrapper-body {
    min-height: 100vh;
    background-color: var(--color-surface);
  }

  .ag-body-horizontal-scroll {
    @include from('medium') {
      position: fixed;
      bottom: 48px;
      left: 90px;
      width: calc(100% - 90px);
      z-index: 3;
      box-sizing: content-box;
    }
  }

  .ag-root-wrapper-body {
    min-height: 100vh;
    background-color: var(--color-surface);
  }

  .ag-paging-panel {
    @include from('medium') {
      width: calc(100% - 90px);
      position: fixed;
      bottom: 0;
      left: 90px;
      background-color: var(--color-surface);
    }
  }

  .ag-overlay-center {
    font-size: var(--fs-small);
    color: var(--color-primary);
    font-weight: 600;
    background-color: var(--color-surface-secondary);
    padding: 5px 10px;
    border-radius: 5px;
  }
}

html[prefers-expanding-menu='enabled'] {
  #myGrid {
    &.fixed {
      @include from('medium') {
        padding: 130px 0 20px;
        .ag-header {
          width: calc(100% - 200px);
          position: fixed;
          top: 0;
          left: 200px;
        }
      }
    }

    @include from('medium') {
      .ag-body-horizontal-scroll {
        position: fixed;
        bottom: 48px;
        left: 200px;
        width: calc(100% - 200px);
        z-index: 3;
        box-sizing: content-box;
      }

      .ag-paging-panel {
        width: calc(100% - 200px);
        position: fixed;
        bottom: 0;
        left: 200px;
      }
    }
  }
}

#myGrid {
  &.fixed {
    @include from('medium') {
      padding: 130px 0 20px;
      .ag-header {
        width: calc(100% - 90px);
        position: fixed;
        top: 0;
        left: 90px;
      }
    }
  }

  .pending-movement-row {
    background-image: url(../images/common/pending-movement-tile.png);
    background-size: auto 100%;
  }

  .pending-unit-row {
    background-image: url(../images/common/pending-unit-tile.png);
    background-size: auto 100%;
  }

  .ghost-row {
    background-image: url(../images/common/ghost-tile.png);
    background-size: auto 100%;
  }

  .uncached-row {
    background-image: url(../images/common/uncached-tile.png);
    background-size: auto 100%;
    background-color: var(--color-surface-secondary);

    * {
      pointer-events: none;
    }

    button,
    input[type='checkbox'] {
      pointer-events: all;
      cursor: pointer;
    }
  }

  .updated-row {
    opacity: 0.5;
  }
  .removed-row {
    background-color: var(--color-surface-senary);
    cursor: not-allowed;
    opacity: 0.5;

    button {
      display: none;
    }
  }

  .uneditable {
    background-color: var(--color-tertiary-light);
  }
}

html[data-theme='dark'] {
  .pending-movement-row {
    background-image: url(../images/common/pending-movement-tile-dark.png) !important;
  }

  .pending-unit-row {
    background-image: url(../images/common/pending-unit-tile-dark.png) !important;
  }

  .ghost-row {
    background-image: url(../images/common/ghost-tile-dark.png) !important;
  }
}

.control_true {
  color: var(--color-quinary);
  text-transform: uppercase;
  // font-size: var(--fs-caption);
  font-weight: 600;
  letter-spacing: 0.2px;
}

.control_false {
  color: var(--color-quaternary);
  text-transform: uppercase;
  // font-size: var(--fs-caption);
  font-weight: 600;
  letter-spacing: 0.2px;
}

.status_active {
  font-weight: 600;
  letter-spacing: 0.2px;
  font-size: var(--fs-caption);
  text-transform: uppercase;
  background-color: var(--color-surface-octonary);
  color: var(--color-quinary);
}

.status_ghost {
  text-transform: uppercase;
  background-color: var(--color-surface-quaternary);
  color: var(--color-default);
  font-weight: 600;
  letter-spacing: 0.2px;
  font-size: var(--fs-caption);
}

.status_pending_movement {
  text-transform: uppercase;
  background-color: var(--color-surface-septenary);
  color: var(--color-secondary);
  font-weight: 600;
  letter-spacing: 0.2px;
  font-size: var(--fs-caption);
}

.status_pending_confirmation {
  text-transform: uppercase;
  background-color: var(--color-surface-senary);
  color: var(--color-quaternary);
  font-weight: 600;
  letter-spacing: 0.2px;
  font-size: var(--fs-caption);
}

.issue_reporting_true {
  font-size: var(--fs-caption);
  text-transform: uppercase;
  color: var(--color-quaternary);
}

.issue_reporting_false {
  font-size: var(--fs-caption);
  color: var(--color-quinary);
  text-transform: uppercase;
}

.own_site_false,
.default_boolean_false,
.currently_operated_false {
  color: var(--color-quaternary);
  font-size: var(--fs-caption);
  font-weight: 600;
  letter-spacing: 0.2px;
}

.own_site_true,
.default_boolean_true,
.currently_operated_true {
  color: var(--color-quinary);
  font-size: var(--fs-caption);
  font-weight: 600;
  letter-spacing: 0.2px;
}

.payment-critical {
  background-color: var(--color-alert-level-5);
  color: var(--color-default-tertiary);
  font-weight: 600;
  pointer-events: none;
  &:before {
    content: '💥 ';
  }
}
.payment-warning {
  background-color: var(--color-alert-level-4);
  font-weight: 600;
  pointer-events: none;
  &:before {
    content: '⚠️ ';
  }
}
.payment-moderate {
  background-color: var(--color-alert-level-3);
  font-weight: 600;
  pointer-events: none;
  &:before {
    content: '💶 ';
  }
}
.payment-valid {
  background-color: var(--color-alert-level-2);
  font-weight: 600;
  pointer-events: none;
  &:before {
    content: '💰 ';
  }
}
.payment-success {
  background-color: var(--color-alert-level-1);
  color: var(--color-default-tertiary);
  font-weight: 600;
  pointer-events: none;
  &:before {
    content: '🥂 ';
  }
}
