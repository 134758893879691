@import 'breakpoint-slicer';
@import '@assets/scss/breakpoints.scss';
@import '@assets/scss/extend.scss';

.main-content {
  display: grid;
  grid-template-columns: 1fr;
  gap: 50px 10px;
  max-width: var(--max-body-width);
  margin: 0 auto;

  @include from('medium') {
    grid-template-columns: 45% auto;
    gap: 20px;
    padding: 30px 20px;
  }

  h3 {
    @extend .h3;
    margin: 0 0 20px;
  }

  .bg-block {
    background-color: var(--color-surface);
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
    display: grid;
    grid-template-rows: auto 1fr;
    min-height: 400px;
  }

  > div:first-child {
    > div + div {
      margin-top: 20px;
    }
  }
}
