@import 'breakpoint-slicer';
@import '@assets/scss/breakpoints.scss';
@import '@assets/scss/extend.scss';

.base-button {
  @extend .button-1;
  outline: 0;
  position: relative;
  padding: 10px 20px;
  display: grid;
  grid-template-columns: 30px auto;
  gap: 10px;
  place-items: center;
  font-family: var(--ff-secondary);

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  &.disabled-underline {
    &:disabled {
      opacity: 1;
      position: relative;

      &:after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        height: 5px;
        width: 100%;
        background-color: var(--color-default-secondary);
      }
    }
  }

  .icon-wrapper {
    border-radius: 50%;
    background-color: var(--color-surface);
    width: 30px;
    display: grid;
    place-items: center;
    height: 30px;
  }
}
