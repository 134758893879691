@import 'breakpoint-slicer';
@import '@assets/scss/breakpoints.scss';
@import '@assets/scss/extend.scss';

.main-header {
  display: grid;
  grid-template-columns: 50px 1fr 50px;
  gap: 20px;
  box-sizing: border-box;
  z-index: 1;
  position: relative;

  span {
    color: var(--color-default);
    font-family: var(--ff-secondary);
    place-self: center;
  }
}
