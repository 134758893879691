@import 'breakpoint-slicer';
@import '@assets/scss/breakpoints.scss';
@import '@assets/scss/extend.scss';

.log {
  background-color: var(--color-surface);
  color: var(--color-default);
  position: relative;
  border-radius: 5px;
  display: block;
  overflow: hidden;
  box-sizing: border-box;
  padding: 10px 20px;
  font-size: var(--fs-small);

  time {
    color: var(--color-tertiary-dark);
    font-size: var(--fs-caption);
    margin-bottom: 10px;
    display: block;
  }

  .log-content {
    line-height: 24px;
  }

  button {
    appearance: none;
    background: none;
    border: none;
    color: inherit;
    font-size: inherit;
    padding: 0;
    margin: 0;
    text-decoration: underline;
    cursor: pointer;
  }

  .pre-wrapper {
    display: block;
    overflow: auto;

    > div {
      display: table;
      table-layout: fixed;
      width: 100%;
    }

    pre {
      font-size: var(--fs-small);
    }

    p {
      background-color: var(--color-default-secondary);
      color: var(--color-default);
      padding: 5px;
      font-weight: 600;
    }
  }

  & + .log {
    margin-top: 10px;
  }
}
