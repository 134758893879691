@import 'breakpoint-slicer';
@import '@assets/scss/breakpoints.scss';
@import '@assets/scss/extend.scss';

.form-checkbox {
  margin-top: 15px;
  color: var(--color-default);

  small {
    font-size: var(--fs-tiny);
    font-family: var(--ff-primary);
    text-transform: uppercase;
    font-weight: 600;
    letter-spacing: 0.2px;
    display: block;
    color: var(--color-tertiary-dark);
  }

  span {
    font-size: var(--fs-small);
  }
}
