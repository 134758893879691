@import 'breakpoint-slicer';
@import '@assets/scss/breakpoints.scss';
@import '@assets/scss/extend.scss';

.widget-header {
  width: 100%;
  box-sizing: border-box;
  position: relative;

  .date {
    > div {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 20px;
    }

    h2 {
      color: var(--color-primary);
      font-size: var(--fs-h2);
      font-family: var(--ff-secondary);
      margin: 0;
    }

    small {
      font-size: var(--fs-small);
      color: var(--color-tertiary-dark);
    }
  }

  .tags-wrapper {
    margin: 20px 0;
  }
}

.table-wrapper {
  margin: 20px 0;

  table,
  th,
  td {
    border: 1px solid var(--color-surface);
    border-collapse: collapse;
    // line-height: 20px;
  }

  .timeline-table {
    border-radius: 5px;
    width: 100%;
    table-layout: fixed;
    border-collapse: collapse;
    border-radius: 5px;
    overflow: hidden;
    box-sizing: border-box;
    background-color: var(--color-surface-quaternary);

    thead {
      background-color: var(--color-surface-secondary);
      position: sticky;
      top: 0;

      th {
        font-size: var(--fs-small);
        color: var(--color-default);
        font-weight: 600;
        font-family: var(--ff-secondary);
        padding: 5px 10px;
      }
    }

    tbody {
      overflow: auto;
      box-sizing: border-box;
      tr {
        // background-color: var(--color-surface-tertiary);

        th {
          font-size: var(--fs-small);
          padding: 0 10px;
          text-transform: uppercase;
        }

        .transfer-before-state {
          p {
            margin: 0;
            background-color: var(--color-surface-senary);
            color: var(--color-default-tertiary);
            border-radius: 10px;
            padding: 2px 10px;
            display: inline-block;
            word-break: normal;
          }
        }
        .transfer-after-state {
          p {
            margin: 0;
            background-color: var(--color-surface-octonary);
            color: var(--color-quinary);
            border-radius: 10px;
            padding: 2px 10px;
            display: inline-block;
            word-break: normal;
          }
        }
      }
      tr:nth-child(2n) {
        background-color: var(--color-surface);
      }

      td {
        word-break: break-all;
        padding: 10px 5px;
        text-align: center;
        font-size: var(--fs-small);
        p {
          margin: 0;
          padding: 0;

          &.old {
            color: var(--color-quaternary);
          }

          &.moderate {
            color: var(--color-secondary);
          }

          &.recent {
            color: var(--color-quinary);
          }

          &.total {
            background-color: var(--color-surface-secondary);
            border-radius: 10px;
            padding: 2px 10px;
            display: inline-block;
          }
        }
      }
    }
  }
}

.timeline-footer {
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
  gap: 20px;
}
