@import 'breakpoint-slicer';
@import '@assets/scss/breakpoints.scss';
@import '@assets/scss/extend.scss';

.in-and-out-section {
  max-width: var(--max-body-width);
  border-radius: 20px;
  display: grid;
  grid-template-columns: 1fr;
  gap: 20px;
  margin-top: 20px;

  // @include from('medium') {
  //   grid-template-columns: 1fr 1fr;
  // }

  article {
    overflow: hidden;
    h3 {
      font-size: var(--fs-h3);
      font-weight: 600;
      margin: 0;
      color: var(--color-primary);
    }

    .wrapper {
      width: 100%;
      position: relative;
      overflow: auto;
      font-size: var(--fs-small);
      padding: 20px 10px;
      border-top: 1px solid var(--color-tertiary-light);
      box-sizing: border-box;
      background-color: var(--color-surface);

      &:last-child {
        border-radius: 0 0 20px 20px;
      }

      @include from('medium') {
        padding: 20px 30px;
        display: grid;
        gap: 50px;
        grid-template-columns: 15% 1fr;
      }

      figure {
        display: none;

        @include from('medium') {
          padding: 20px 30px;
          display: block;
          padding: 0;
          margin: 0;
        }

        img {
          width: 100%;
        }
      }

      > div {
        box-sizing: border-box;

        > div {
          position: relative;
          background-color: var(--color-surface-secondary);
          border-radius: 5px;
          padding: 20px;

          &:not(:first-child) {
            margin: 20px 0 0;
          }
        }

        pre {
          margin: 10px 0 0;
          border-radius: 5px;
          box-sizing: border-box;
          font-size: inherit;
          white-space: pre-wrap;
          word-wrap: break-word;
          font-family: inherit;
          line-height: 26px;
        }
      }
    }
  }
}
