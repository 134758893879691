@import 'breakpoint-slicer';
@import '@assets/scss/breakpoints.scss';
@import '@assets/scss/extend.scss';

.menu {
  position: fixed;
  z-index: 9;
  bottom: 0;
  left: 0;
  width: 100%;
  // overflow-x: auto;
  border-top: 1px solid var(--color-tertiary-light);
  background-color: var(--color-surface);
  box-sizing: border-box;
  transition: width 250ms ease-in-out;

  @include from('medium') {
    padding: 0;
    border-top: none;
    top: 0;
    left: 0;
    width: 70px;
    height: 100%;
  }

  .flex {
    display: flex;
    position: relative;
    background-color: var(--color-surface);
    z-index: 1;
    align-items: stretch;
    place-items: center;
    box-sizing: border-box;
    padding: 15px;
    overflow: auto;

    @include from('medium') {
      place-items: unset;
      gap: 30px 0;
      flex-direction: column;
      overflow-x: unset;
      height: 100%;
      padding: 10px 20px;
    }

    &-item {
      display: flex;

      @include from('medium') {
        display: grid;
        place-items: start;
      }

      &.grid-nav-items {
        flex-grow: 1;
        display: flex;
        // overflow: auto;
        white-space: nowrap;
        @include from('medium') {
          display: grid;
        }

        > div {
          align-self: start;
          position: relative;
          display: grid;
          grid-auto-flow: column;
          column-gap: 10px;

          @include from('medium') {
            grid-auto-flow: unset;
            column-gap: 0;
            row-gap: 25px;
          }
        }
      }
    }

    .logo {
      border: none;
      background: none;
      cursor: pointer;
      width: 30px;
      height: auto;
      // place-self: center;
      margin: 0 10px;
      padding: 0;

      @include from('medium') {
        margin: 35px 0 55px;
      }

      img {
        width: 100%;
      }
    }
  }

  // Menu panel
  .menu-panel {
    position: fixed;
    border-radius: 0 20px 20px 0;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: var(--color-surface);

    @include from('medium') {
      height: calc(100% - 84px);
      position: absolute;
      top: auto;
      left: 100%;
      bottom: 0;
      width: 350px;
      background-color: var(--color-surface-quaternary);
      // background-image: url(../../assets/images/menu/gradient-bg.png);
      background-repeat: no-repeat;
      background-position: center bottom;
      background-size: contain;
    }

    .section-title {
      margin: 70px 30px 45px;
      padding: 5px 10px;
      background-color: var(--color-surface-secondary);
      font-size: var(--fs-h1);
      display: inline-block;
      border-radius: 20px;
      font-family: var(--ff-secondary);
    }

    ul {
      list-style: none;
      padding-bottom: 50px;

      @include from('medium') {
        padding: 0;
      }

      li {
        padding: 0 10px;
      }

      li + li {
        margin-top: 10px;
      }
    }
  }

  .logout {
    margin-left: 10px;
    @include from('medium') {
      margin-left: 0;
    }

    > div {
      flex-grow: 1;
      display: flex;
      white-space: nowrap;
      gap: 10px;
      @include from('medium') {
        margin-bottom: 20px;
        gap: 10px;
        display: flex;
        flex-direction: column;
      }
    }
  }
}
