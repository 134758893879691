@import 'breakpoint-slicer';
@import '@assets/scss/breakpoints.scss';
@import '@assets/scss/extend.scss';

.transfers-header {
  display: flex;
  padding: 0;
  gap: 10px;
  flex-wrap: wrap;
  @include from('medium') {
    padding: 0 20px;
  }
}
.content-container {
  display: flex;
  flex-direction: column;
  padding-bottom: 30px;

  &-header {
    padding: 0;
    margin: 10px 0;

    @include from('medium') {
      margin: 30px 0 10px;
      padding: 0 20px;
    }
  }
}
