@import 'breakpoint-slicer';
@import '@assets/scss/breakpoints.scss';
@import '@assets/scss/extend.scss';

.base-list {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;

  &-item {
    background-color: var(--color-surface-secondary);
    border-radius: 10px;
    color: var(--color-default);
    font-size: var(--fs-small);
    font-weight: 500;
    padding: 15px 0;
    text-align: center;
  }
}
