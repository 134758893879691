@import 'breakpoint-slicer';
@import '@assets/scss/breakpoints.scss';
@import '@assets/scss/extend.scss';

.in-and-out-section {
  max-width: var(--max-body-width);
  border-radius: 20px;
  display: grid;
  grid-template-columns: 1fr;
  gap: 20px;
  margin-top: 20px;

  // @include from('medium') {
  //   grid-template-columns: 1fr 1fr;
  // }

  article {
    overflow: hidden;
    h3 {
      font-size: var(--fs-h3);
      font-weight: 600;
      margin: 0 0 20px;
      color: var(--color-primary);
    }

    > div {
      padding: 20px 10px;
      border-top: 1px solid var(--color-tertiary-light);
      border-radius: 0 0 20px 20px;
      background-color: var(--color-surface);

      @include from('medium') {
        padding: 20px 30px;
      }

      .in-and-out-wrapper {
        width: 100%;
        position: relative;
        overflow: auto;
        > p {
          margin: 20px 0;
          background-color: var(--color-surface-quaternary);
          border-radius: 5px;
          padding: 10px;
          font-size: var(--fs-small);
        }
      }
    }
  }
}
