@import 'breakpoint-slicer';
@import '@assets/scss/breakpoints.scss';
@import '@assets/scss/extend.scss';

.site-weather {
  .weekly-weather {
    display: grid;
    grid-template-columns: 1fr;
    box-sizing: border-box;
    gap: 10px;

    @include from('medium') {
      grid-template-columns: 1fr 1fr;
    }

    .weather-day {
      background-color: var(--color-surface-tertiary);
      border-radius: 10px;
      padding: 10px 20px;
      box-sizing: border-box;

      .weather-label {
        color: var(--color-default-secondary);
        font-size: var(--fs-small);
        margin: 0;
        padding: 0;
      }

      .weather-description {
        display: grid;
        grid-template-columns: 75px auto;
        color: var(--color-default-secondary);

        .weather-temperature {
          border-right: 1px solid var(--color-surface);
          padding-right: 20px;

          &-day {
            font-family: var(--ff-secondary);
            font-size: var(--fs-big-size);
            margin: 0;
          }

          &-minmax {
            display: flex;
            justify-content: space-between;
            margin: 0;
            font-size: var(--fs-small);
          }
        }
      }

      .weather-details {
        font-size: var(--fs-caption);
        padding-left: 20px;
        display: grid;
        grid-template-columns: 1fr 40px;
        box-sizing: border-box;
        gap: 10px;
        width: 100%;
        @include from('medium') {
          grid-template-columns: 1fr 60px;
        }

        p {
          margin: 0;
          padding: 0;
          width: 100%;
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
        }

        .weather-units {
          > p:first-child {
            margin: 0 0 5px;
          }
        }

        .weather-icon {
          font-size: 40px;
          @include from('medium') {
            font-size: 50px;
          }
        }
      }
    }
  }

  // p {
  //   margin: 0;
  //   font-size: var(--fs-small);
  //   margin: 5px 0;
  //   display: grid;
  //   grid-template-columns: 1fr auto;
  //   gap: 10px;

  //   &.low strong {
  //     color: var(--color-alert-level-1);
  //   }

  //   &.moderate strong {
  //     color: var(--color-alert-level-2);
  //   }

  //   &.high strong {
  //     color: var(--color-alert-level-3);
  //   }

  //   &.very-high strong {
  //     color: var(--color-alert-level-4);
  //   }

  //   &.extreme strong {
  //     color: var(--color-alert-level-5);
  //   }
  // }

  // .weather-date {
  //   text-align: center;
  //   font-size: var(--fs-h3);
  // }
  // .weather-icon {
  //   font-size: var(--fs-big-size);
  //   text-align: center;
  //   margin: 10px 0;
  // }
}
