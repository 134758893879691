@import 'breakpoint-slicer';
@import '@assets/scss/breakpoints.scss';
@import '@assets/scss/extend.scss';

.term {
  .subtitle {
    font-size: var(--fs-small);
  }

  &-row-wrapper:not(:first-child) {
    margin-top: 15px;
  }

  .users {
    margin-top: 5px;
    display: flex;
    justify-content: flex-end;

    small {
      font-size: var(--fs-caption);
      display: inline-block;
      margin-right: 5px;
    }

    .animation {
      transform-origin: center center;
      height: 20px;
      width: 20px;
      &:hover {
        animation: rotate 5s normal linear infinite;
      }
    }

    .empty-icon {
      opacity: 0.5;
    }
  }

  &-row {
    display: grid;
    grid-template-columns: 1fr 1fr 30px 30px;
    align-items: center;
    gap: 10px;

    .validated {
      background-color: var(--color-surface-octonary);
      font-size: var(--fs-caption);
      color: var(--color-quinary);
      letter-spacing: 0.3px;
      font-weight: 600;
      padding: 5px 10px;
      border-radius: 4px;
      display: grid;
      place-content: center;
    }

    .pending {
      background-color: var(--color-surface-septenary);
      font-size: var(--fs-caption);
      color: var(--color-secondary);
      font-weight: 600;
      padding: 5px 10px;
      border-radius: 4px;
      display: grid;
      place-content: center;
    }
  }

  &-row-validated {
    grid-template-columns: 1fr 1fr 70px;
  }

  &:not(:first-child) {
    margin-top: 20px;
  }

  h3 {
    font-size: var(--fs-h3);
    font-weight: 600;
    margin: 0 0 20px;
    color: var(--color-primary);
  }

  .add-more {
    @extend .button-1;
    margin-top: 20px;
  }

  .render-field {
    background-color: var(--color-tertiary-light);
    border-radius: 10px;
    overflow: hidden;
    font-weight: normal;
    padding: 4px 10px 0;

    &-disabled {
      background-color: transparent;
    }

    small {
      letter-spacing: 0.2px;
      font-size: var(--fs-tiny);
      text-transform: uppercase;
      color: var(--color-tertiary-dark);
      font-weight: 600;
      display: block;
    }

    input {
      color: var(--color-default);
      font-size: var(--fs-small);
      font-family: var(--ff-primary);
      appearance: none;
      border: none;
      background-color: transparent;
      width: 100%;
      outline: 0;
      padding: 4px 0 5px;

      &:disabled {
        cursor: not-allowed;
        color: var(--color-default);
        -webkit-text-fill-color: var(--color-default);
      }
    }
  }

  span {
    font-size: var(--fs-small);
    -webkit-text-fill-color: var(--color-default);
  }

  .calendar-style {
    font-family: inherit;
    font-size: var(--fs-small);
    z-index: 1;
    position: relative;
  }
}

@keyframes rotate {
  0% {
    transform: rotateZ(0);
  }
  25% {
    transform: rotateZ(90deg);
  }
  50% {
    transform: rotateZ(180deg);
  }
  75% {
    transform: rotateZ(270deg);
  }
  100% {
    transform: rotateZ(360deg);
  }
}
